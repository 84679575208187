import logo from "../../assets/LogoHeader.svg";
import user from "../../assets/user.svg";
import img1 from "../../assets/img1.svg";
import img2 from "../../assets/img2.svg";
import doodle from "../../assets/doodle.png";
import posticon from "../../assets/posticon.svg";
import tutoricon from "../../assets/tutoricon.svg";
import { useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import FooterBack from "../../assets/FooterBack.svg";
import LogoFooter from "../../assets/LogoFooter.svg";
import location2 from "../../assets/location2.svg";
import Phone from "../../assets/Phone.svg";
import instagram from "../../assets/instagram.svg";
import twitter from "../../assets/twitter.svg";
import yt from "../../assets/youtube.png";
import linkedin from "../../assets/linkedin.png";
import Email from "../../assets/Email.svg";
import GooglePlay from "../../assets/GooglePlay.svg";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import NotFound from "../pages/LandingPage/NotFound";
import WhatAppMobile from "../pages/LandingPage/WhatAppMobile";

export const HomeTutorsJaipur = () => {
  const { slug } = useParams();
  const url = `https://app.kanhahometutions.com/api/v1/seo-pages?slug=${slug}`;
  const [title, setTitle] = useState(slug.replaceAll('-', ' '));
  const [error, setError] = useState(false); 
  const [loading, setLoading] = useState(true); 

 
  async function getSlug() {
    try {
      const response = await axios.get(url);
      if (response.data.slugs && response.data.slugs.length > 0) {
        const data = response.data.slugs[0];
        const txt_meta_title = data.txt_meta_title;
        setTitle(txt_meta_title);
      } else {
        setError(true); 
      }
    } catch (error) {
      console.error('Error fetching slugs:', error);
      setError(true); 
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    
    getSlug();
  }, []);

  const isXs = useMediaQuery("(min-width:600px) and (max-width:3000px)");

  const handleEmailClick = () => {
    const mailtoLink = `mailto:kanhahometutions@gmail.com?subject=&body=`;
    window.open(mailtoLink, "_blank");
  };

  const khttwitter = () => {
    const playStoreUrl = "https://x.com/kanhahometution?s=21";
    window.open(playStoreUrl, "_blank");
  };

  const youtube = () => {
    const playStoreUrl = "https://www.youtube.com/@Kanhahome";
    window.open(playStoreUrl, "_blank");
  };

  const khtlinkedin = () => {
    const playStoreUrl =
      "https://www.linkedin.com/company/kanha-home-tuitions/posts/?feedView=all";
    window.open(playStoreUrl, "_blank");
  };

  const khtinstagram = () => {
    const playStoreUrl =
      "https://www.instagram.com/kanha_home_tuition?igsh=c29rOWFyY29wdm5m";
    window.open(playStoreUrl, "_blank");
  };

  const handleClickfortutor = () => {
    const playStoreUrl = "https://play.google.com/store/apps/details?id=com.kanhahometutions.tutor";
    window.open(playStoreUrl, "_blank");
  };

  const handleClickforstudent = () => {
    const playStoreUrl = "https://play.google.com/store/apps/details?id=com.kanhahometutions.student";
    window.open(playStoreUrl, "_blank");
  };

  
  const openSocialMediaLink = (link) => {
    window.open(link, "_blank");
  };

  if (loading) {
    return <div>Loading...</div>; 
  }

  if (error) {
    return (
      <>
      <NotFound/>
      
      </>
    );
  }


  return (
    <>
                          <WhatAppMobile/>

      <div
        style={{
          backgroundColor: "#C68863",
          width: "100%",
          height: isXs ? "45rem" :'53rem',
          padding: "1.5rem 3rem",
          display: "flex",
          flexDirection: "column",
          gap: isXs ? "4rem" : "2.7rem",
          position: "relative",
          backgroundImage: `url(${doodle})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: isXs ? "" : "column",
            gap: "2rem",
          }}
        >
          <div style={{ textAlign: "center" }}>
            <a href="/">
              <img
                style={{ width: isXs ? "" : "100%" }}
                width={200}
                src={logo}
                alt=""
              />
            </a>
          </div>
          <div
            style={{
              display: "flex",
              gap:isXs? "1rem":'1.5rem',
              flexDirection: isXs ? "" : "column",
            }}
          >
            <button
              style={{
                width: "11rem",
                height: "3.5rem",
                border: "3px solid white",
                backgroundColor: "rgb(255,255,255 ,.1)",
                color: "white",
                borderRadius: "20rem",
                fontSize: isXs ? "1rem" : ".9rem",
                fontWeight: "500",
                boxShadow: "4px 7px rgb(000,000,000 ,.1)  ",
              }}
            >
              <Link
                to="/student-signin"
                style={{
                  color: "white",
                  textDecoration: "none", 
                }}
              >
                Login as Student
              </Link>
            </button>
            <button
              style={{
                width: "11rem",
                height: "3.5rem",
                border: "3px solid white",
                backgroundColor: "rgb(255,255,255 ,.1)",
                color: "white",
                borderRadius: "20rem",
                fontSize: isXs ? "1rem" : ".9rem",

                fontWeight: "500",
                boxShadow: "4px 7px rgb(000,000,000 ,.1)  ",
              }}
            >
              <Link
                to="/signin-teacher"
                style={{ color: "white", textDecoration: "none" }}
              >
                Login as Tutor
              </Link>
            </button>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            fontFamily: "jost",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
            gap: "1rem",
          }}
        >
          <h1
            style={{
              textAlign: "center",
              marginTop:isXs?'':'1rem',
              fontSize: isXs ? "3rem" : "1.8rem",
            }}
          >
            {title}
          </h1>

          <div
            style={{
              display:isXs ? "flex" :'none',
              alignItems: "center",
              justifyContent: "center",
              gap: "1rem",
              fontSize: isXs ? "" : ".5rem",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: ".2rem",
              }}
            >
              <img style={{height: isXs ? '':'16px'}} src={user} alt="" />
              <span style={{fontSize:isXs ? '':'10px'}}>Sign Up Easily</span>
            </div>
            <div>|</div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: ".2rem",
              }}
            >
              <img style={{height: isXs ? '':'16px'}}  src={posticon} alt="" />
              <span style={{fontSize:isXs ? '':'10px'}}>Post Tutoring Requirements</span>
            </div>
            <div>|</div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: ".2rem",
              }}
            >
              <img style={{height: isXs ? '':'16px'}}  src={tutoricon} alt="" />
              <span style={{fontSize:isXs ? '':'10px'}} >Choose the Ideal Tutor</span>
            </div>
          </div>

          <span
            style={{ width: isXs? "35%":'100%', textAlign: "center", marginTop: isXs? "1rem":'1rem', fontSize:isXs ? '':'19px' }}
          >
            Connect with expert tutors tailored to fit your academic needs and
            personal learning style.
          </span>
          <span>
          <a href="/student-signin">
          <button
            style={{
              padding: ".8rem 4rem",
              border: "none",
              boxShadow: "4px 8px 4px rgb(0, 0, 0, .1)",
              borderRadius: "5px",
              fontSize: "1.4rem",
              marginTop: isXs? "3rem":"2rem" ,
              fontWeight: "bold",
              backgroundColor: "#FFDB7E",
              color: "#866D2C",
              gap: "40rem",
              width: isXs? '':'22.4rem',
            }}
            type="button"
          >
            Post Your Requirement
          </button>
        </a>
          </span>
        </div>

        <div
          style={{
            display: isXs ? "flex" :'none',
            zIndex: "",
            justifyContent: "space-between",
            alignItems: "center",
            position: "absolute",
            gap: isXs ? "40rem" :'7rem',
            top: isXs?"29.5rem":'45rem',
          }}
        >
          <img style={{ width: isXs ? "20%" : "30%" }} src={img1} alt="" />
          <img style={{ width: isXs ? "20%" : "30%" }}  src={img2} alt="" />
        </div>

       
      </div>

      <div style={{ position: "absolute", top: isXs?"40rem" :"53rem", zIndex: "10" }}>
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: isXs ? "" : "center",
            backgroundColor: isXs ? "" : "#20140E",
            paddingBottom: isXs ? "" : "25%",
          }}
        >
          <img
            src={FooterBack}
            alt="Frame24"
            style={{
              position: "absolute",
              width: "100%",
              maxHeight: "100%",
              zIndex: -2,
              display: isXs ? "" : "none",
            }}
          />
          <Grid
            item
            xs={10}
            sm={3}
            md={3}
            lg={3}
            xl={3}
            xxl={3}
            spacing={3}
            sx={{ marginTop: "5%" }}
          >
            <Box style={{ marginTop: "7%", display: "flex" }}>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Box sx={{ textAlign: "center" }}>
                  <img
                    src={LogoFooter}
                    alt="LogoFooter"
                    style={{ width: "70%" }}
                  />
                </Box>
                <Box
                  style={{
                    width: isXs ? "60%" : "95%",
                    color: "white",
                    fontSize: isXs ? "1vw" : "3.8vw",
                    textAlign: isXs ? "left" : "center",
                    marginLeft: isXs ? "3.5rem" : "0rem",
                    marginTop: isXs ? "" : "2%",
                  }}
                >
                  Empower your learning journey with Kanha Home Tuitions—where
                  potential meets possibility right at your doorstep!
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={10}
            sm={3}
            md={3}
            lg={3}
            xl={3}
            xxl={3}
            spacing={3}
            sx={{ marginTop: "8%" }}
          >
            <Box
              style={{
                marginTop: "10%",
                fontSize: "1vw",
                minWidth: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{ width: "80%", display: "flex", justifyContent: "center" }}
              >
                <Box
                  sx={{
                    width: "60%",
                    display: "flex",
                    flexDirection: "column",
                    gap: ".3rem",
                  }}
                >
                  <a href="/" style={{ textDecoration: "none" }}>
                    {" "}
                    <button
                      className="d-flex align-items-center"
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                        width: "100%",
                        justifyContent: isXs ? "" : "center",
                      }}
                    >
                      <Box
                        style={{
                          color: "white",
                          marginLeft: "5%",
                          marginTop: "0%",
                          fontSize: isXs ? "" : "3.8vw",
                        }}
                      >
                        Home
                      </Box>
                    </button>
                  </a>
                  <a href="/privacy-policy" style={{ textDecoration: "none" }}>
                    <button
                      className="d-flex align-items-center"
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                        width: "100%",
                        justifyContent: isXs ? "" : "center",
                      }}
                    >
                      <Box
                        style={{
                          color: "white",
                          marginLeft: "5%",
                          marginTop: "5%",
                          fontSize: isXs ? "" : "3.8vw",
                        }}
                      >
                        Privacy Policy
                      </Box>
                    </button>
                  </a>
                  <a href="/refund-policy" style={{ textDecoration: "none" }}>
                    {" "}
                    <button
                      className="d-flex align-items-center"
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                        width: "100%",
                        justifyContent: isXs ? "" : "center",
                      }}
                    >
                      <Box
                        style={{
                          color: "white",
                          marginLeft: "5%",
                          marginTop: "5%",
                          fontSize: isXs ? "" : "3.8vw",
                        }}
                      >
                        Refund Policy
                      </Box>
                    </button>
                  </a>
                  <a
                    href="/terms-conditions"
                    style={{ textDecoration: "none" }}
                  >
                    {" "}
                    <button
                      className="d-flex align-items-center"
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                        width: "100%",
                        justifyContent: isXs ? "" : "center",
                      }}
                    >
                      <Box
                        style={{
                          color: "white",
                          marginLeft: "5%",
                          marginTop: "5%",
                          fontSize: isXs ? "" : "3.8vw",
                        }}
                      >
                        Terms & Conditions
                      </Box>
                    </button>
                  </a>

                  <a
                    href="/sitemap"
                    style={{ textDecoration: "none" }}
                  >
                    {" "}
                    <button
                      className="d-flex align-items-center"
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                        width: "100%",
                        justifyContent: isXs ? "" : "center",
                      }}
                    >
                      <Box
                        style={{
                          color: "white",
                          marginLeft: "5%",
                          marginTop: "5%",
                          fontSize: isXs ? "" : "3.8vw",
                        }}
                      >
                        Sitemap
                      </Box>
                    </button>
                  </a>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={10}
            sm={3}
            md={3}
            lg={3}
            xl={3}
            xxl={3}
            spacing={3}
            sx={{ marginTop: "8%" }}
          >
            <Box
              style={{
                marginRight: "10%",
                marginTop: "10%",
                fontSize: "1vw",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
              >
                <Box className="d-flex align-items-center ">
                  <img src={location2} alt="location2" width="8%" />
                  <Box
                    style={{
                      color: "white",
                      marginLeft: "5%",
                      minWidth: isXs ? "95%" : "95%",
                      fontSize: isXs ? "" : "3.8vw",
                    }}
                  >
                    Plot No. 45, Vaishali Nagar, Jaipur
                  </Box>
                </Box>
                <Box className="d-flex align-items-center">
                  <img src={Phone} alt="Phone" width="7%" />
                  <Box
                    style={{
                      color: "white",
                      marginLeft: "5%",

                      fontSize: isXs ? "" : "3.8vw",
                    }}
                  >
                    <a
                      href="tel:+91-6376400386"
                      style={{
                        fontSize: "15px",
                        color: "white",
                        textDecoration: "none",
                      }}
                    >
                      +91-6376400386
                    </a>
                  </Box>
                </Box>
                <Box className="d-flex align-items-center">
                  <img src={Email} alt="Email" width="7%" />
                  <Box
                    style={{
                      color: "white",
                      marginLeft: "5%",
                      marginTop: "0%",
                      fontSize: isXs ? "" : "3.8vw",
                    }}
                  >
                    <div onClick={handleEmailClick}>
                    info@kanhahometutions.com
                                  </div>
                  </Box>
                </Box>
                <Box className="d-flex align-items-center">
                  <img src={Email} alt="Email" width="7%" />
                  <Box
                    style={{
                      color: "white",
                      marginLeft: "5%",
                      marginTop: "0%",
                      fontSize: isXs ? "" : "3.8vw",
                    }}
                  >
                    <div onClick={handleEmailClick}>
                      kanhahometutions@gmail.com
                    </div>
                  </Box>
                </Box>
                <Box sx={{ display: "flex", gap: "1.5rem", marginTop: "1rem" }}>
                  <Box>
                    <img
                      onClick={khtinstagram}
                      src={instagram}
                      alt=""
                      width={30}
                    />
                  </Box>
                  {/* <Box><img onClick={khtfacebook} src={facebook} alt="" /></Box> */}
                  <Box>
                    <img onClick={khttwitter} src={twitter} alt="" width={30} />
                  </Box>
                  <Box>
                    <img onClick={youtube} src={yt} alt="" width={30} />
                  </Box>
                  <Box>
                    <img
                      onClick={khtlinkedin}
                      src={linkedin}
                      alt=""
                      width={30}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={10}
            sm={3}
            md={3}
            lg={3}
            xl={3}
            xxl={3}
            spacing={3}
            sx={{ marginTop: "6%" }}
          >
            <Box
              style={{
                marginTop: isXs ? "10%" : "1%",
                minWidth: "100%",
                textAlign: "left",
                display: "flex",
                flexDirection: isXs ? "column" : "row",
                gap: "1rem",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: isXs ? "1rem" : ".5rem",
                }}
              >
                <Box style={{ color: "white" }}>For Tutor</Box>

                <img
                  onClick={handleClickfortutor}
                  src={GooglePlay}
                  alt="GooglePlay"
                  style={{ width: isXs ? "60%" : "90%" }}
                />
              </Box>

              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: isXs ? "1rem" : ".5rem",
                }}
              >
                <Box style={{ color: "white" }}>For Learner</Box>

                <img
                  onClick={handleClickforstudent}
                  src={GooglePlay}
                  alt="GooglePlay"
                  style={{ width: isXs ? "60%" : "90%" }}
                />
              </Box>
              {/* <Box style= {{color: "white"}}>
                            For Learner
                        </Box>
                        <Link to="https://play.google.com/store/apps/details?id=com.kanhahometutions.tutor">
                        <img src={GooglePlay} alt="GooglePlay" width="50%" />
                        </Link> */}
            </Box>
          </Grid>
        </Grid>

        {/* footer */}
        <Grid
          container
          className="py-3 px-2"
          sx={{
            marginTop: isXs ? "2%" : "",
            backgroundColor: "#A6653F",
            fontSize: isXs ? "1vw" : "3vw",
            color: "white",
          }}
        >
          <Box
            style={{ textAlign: isXs ? "start" : "center", margin: "0 auto" }}
          >
            &copy;2024 Kanha Home Tutions. All Right Reserved{" "}
          </Box>
          <Box style={{ textAlign: isXs ? "end" : "center", margin: "0 auto" }}>
            Powered by Infomagine Softwares Pvt. Ltd.
          </Box>
        </Grid>
      </div>
    </>
  );
};
