import React from 'react'
import logo from "../../../assets/TClogo.png";
import { Grid, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import FooterBack from "../../../assets/FooterBack.svg";
import LogoFooter from "../../../assets/LogoFooter.svg";
import location2 from "../../../assets/location2.svg";
import Phone from "../../../assets/Phone.svg";
import Email from "../../../assets/Email.svg";
import GooglePlay from "../../../assets/GooglePlay.svg";
import AppStore from "../../../assets/AppStore.svg";
import Footer from '../LandingPage/Footer';
import { Link, useNavigate } from 'react-router-dom';
import WhatAppMobile from '../LandingPage/WhatAppMobile';

const TermsAndConditionsMobile = () => {
    const isXs = useMediaQuery('(min-width:600px)');
    const navigate = useNavigate();
    const navigateLanding = () => {
        navigate('/')
    }

    return (
        <>
                      <WhatAppMobile/>

            <Box style={{ width: '100%' }}>
                <Box style={{ width: '100%' }}>
        

                    <Box style={{ padding: '2rem', display: 'flex', flexDirection: 'column', gap: '1.3rem', width:isXs? '70%':'100%' }}>
                        <Box>
                            <p>
                                Welcome to Kanha Home Tutions ("we," "our," or "us"). These Terms
                                and Conditions "Terms" govern your use of our website
                                kanhahometutions.com and the services provided through it. By
                                accessing or using our Website, you agree to be bound by these
                                Terms. If you do not agree with any part of these Terms, please do
                                not use our Website.
                            </p>
                        </Box>
                        <Box>
                            <h1 style={{ fontSize: '1.2rem', fontWeight: 600 }}>1. Acceptance of Terms</h1>
                        </Box>

                        <Box>
                            <p>
                                By accessing and using our Website, you agree to comply with and
                                be legally bound by these Terms, as well as our Privacy Policy. If
                                you do not agree to these Terms, you must not use our services.
                            </p>
                        </Box>

                        <Box>
                            <h1 style={{ fontSize: '1.2rem', fontWeight: 600 }}>2. Services Provided</h1>
                        </Box>
                        <Box>
                            <p>
                                Kanha Home Tutions is a platform that connects students with
                                tutors based on their specific needs. Students and tutors can
                                discuss and agree on the timing and fees for the classes. Tutors
                                can register their profiles and verify them by paying a
                                verification fee. Additionally, tutors need to pay a fee to unhide
                                student inquiry details.
                            </p>
                        </Box>
                        <Box>
                            <h1 style={{ fontSize: '1.2rem', fontWeight: 600 }}>3. User Registration</h1>
                        </Box>
                        <Box>
                            <p>
                                To use certain features of our Website, you must register for an
                                account. When registering, you agree to:
                            </p>
                        </Box>
                        <Box style={{ padding: '0rem 1rem', lineHeight: '2rem' }}>
                            <ul>
                                <li>Provide accurate, current, and complete information.</li>
                                <li>Maintain and promptly update your account information.</li>
                                <li>
                                    Maintain the security of your password and accept all risks of
                                    unauthorized access to your account.
                                </li>
                                <li>
                                    Notify us immediately of any unauthorized use of your account.
                                </li>
                            </ul>
                        </Box>
                        <Box>
                            <h1 style={{ fontSize: '1.2rem', fontWeight: 600 }}>4. Fees and Payments</h1>
                        </Box>

                        <Box style={{ padding: '0rem 1rem', lineHeight: '2rem' }}>
                            <ul>
                                <li>
                                    Verification Fees: Tutors are required to pay a verification fee
                                    to verify their profiles. This fee is processed through a
                                    third-party API.
                                </li>
                                <li>
                                    Inquiry Fees: Tutors must pay a fixed fee to unhide student
                                    inquiry details.
                                </li>
                                <li>
                                    Payment Methods: All payments must be made through the payment
                                    methods available on our Website. You agree to provide current,
                                    complete, and accurate billing information.
                                </li>
                            </ul>
                        </Box>

                        <Box>
                            <h1 style={{ fontSize: '1.2rem', fontWeight: 600 }}>5. User Responsibilities</h1>
                        </Box>
                        <Box>
                            <p>As a user of our Website, you agree to:</p>
                        </Box>
                        <Box style={{ padding: '0rem 1rem', lineHeight: '2rem' }}>
                            <ul>
                                <li>
                                    Use the Website only for lawful purposes and in accordance with
                                    these Terms.
                                </li>
                                <li>
                                    Not engage in any activity that interferes with or disrupts the
                                    Website.
                                </li>
                                <li>
                                    Not attempt to gain unauthorized access to any part of the
                                    Website or any other user's account.
                                </li>
                                <li>
                                    Not use the Website to transmit any harmful or malicious code
                                </li>
                            </ul>
                        </Box>

                        <Box>
                            <h1 style={{ fontSize: '1.2rem', fontWeight: 600 }}>6. Tutor and Student Interactions</h1>
                        </Box>
                        <Box style={{ padding: '0rem 1rem', lineHeight: '2rem' }}>
                            <ul>
                                <li>
                                    Agreement: The terms of any tutoring arrangement, including
                                    timing, fees, and location, are to be agreed upon between the
                                    student and the tutor.
                                </li>
                                <li>
                                    Conduct: Both students and tutors are expected to conduct
                                    themselves professionally and respectfully.
                                </li>
                                <li>
                                    Disputes: We are not responsible for any disputes or
                                    disagreements between students and tutors. However, we may offer
                                    assistance to help resolve issues at our discretion
                                </li>
                            </ul>
                        </Box>

                        <Box>
                            <h1 style={{ fontSize: '1.2rem', fontWeight: 600 }}>7. Intellectual Property</h1>
                        </Box>
                        <Box>
                            <p>
                                All content on the Website, including text, graphics, logos, and
                                software, is the property of Kanha Home Tutions or its licensors
                                and is protected by intellectual property laws. You may not use,
                                reproduce, or distribute any content from the Website without our
                                prior written permission.
                            </p>
                        </Box>

                        <Box>
                            <h1 style={{ fontSize: '1.2rem', fontWeight: 600 }}>8. Third-Party Links</h1>
                        </Box>
                        <Box>
                            <p>
                                Our Website may contain links to third-party websites or services
                                that are not owned or controlled by Kanha Home Tutions. We have no
                                control over, and assume no responsibility for, the content,
                                privacy policies, or practices of any third-party websites or
                                services. You acknowledge and agree that Kanha Home Tutions shall
                                not be responsible or liable, directly or indirectly, for any
                                damage or loss caused or alleged to be caused by or in connection
                                with the use of any such content, goods, or services available on
                                or through any such websites or services.
                            </p>
                        </Box>

                        <Box>
                            <h1 style={{ fontSize: '1.2rem', fontWeight: 600 }}>9. Limitation of Liability</h1>
                        </Box>
                        <Box>
                            <p>
                                To the maximum extent permitted by law, Kanha Home Tutions shall
                                not be liable for any indirect, incidental, special,
                                consequential, or punitive damages, or any loss of profits or
                                revenues, whether incurred directly or indirectly, or any loss of
                                data, use, goodwill, or other intangible losses, resulting from:
                            </p>
                        </Box>
                        <Box style={{ padding: '0rem 1rem', lineHeight: '2rem' }}>
                            <ul>
                                <li>Your use or inability to use the Website.</li>
                                <li>
                                    Any unauthorized access to or use of our servers and/or any
                                    personal information stored therein.
                                </li>
                                <li>
                                    Any interruption or cessation of transmission to or from the
                                    Website.
                                </li>
                                <li>
                                    Any bugs, viruses, trojan horses, or the like that may be
                                    transmitted to or through our Website by any third party.
                                </li>
                                <li>
                                    Any errors or omissions in any content or for any loss or damage
                                    incurred as a result of the use of any content posted, emailed,
                                    transmitted, or otherwise made available through the Website.
                                </li>
                                <li>
                                    User interactions and arrangements made through the Website.
                                </li>
                            </ul>
                        </Box>
                        <Box>
                            <h1 style={{ fontSize: '1.2rem', fontWeight: 600 }}>10. Indemnification</h1>
                        </Box>
                        <Box>
                            <p>
                                You agree to defend, indemnify, and hold harmless Kanha Home
                                Tutions, its affiliates, and their respective directors, officers,
                                employees, and agents, from and against any claims, liabilities,
                                damages, losses, and expenses, including, without limitation,
                                reasonable legal and accounting fees, arising out of or in any way
                                connected with:
                            </p>
                        </Box>
                        <Box style={{ padding: '0rem 1rem', lineHeight: '2rem' }}>
                            <ul>
                                <li>Your access to or use of the Website.</li>
                                <li>Your violation of these Terms.</li>
                                <li>
                                    Your violation of any third-party right, including without
                                    limitation any intellectual property, property, or privacy
                                    right.
                                </li>
                                <li>
                                    Any claim that your use of the Website caused damage to a third
                                    party.
                                </li>
                            </ul>
                        </Box>
                        <Box>
                            <h1 style={{ fontSize: '1.2rem', fontWeight: 600 }}>11. Termination</h1>
                        </Box>
                        <Box>
                            <p>
                                We may terminate or suspend your account and bar access to the
                                Website immediately, without prior notice or liability, under our
                                sole discretion, for any reason whatsoever and without limitation,
                                including but not limited to a breach of these Terms. If you wish
                                to terminate your account, you may simply discontinue using the
                                Website.
                            </p>
                        </Box>
                        <Box>
                            <h1 style={{ fontSize: '1.2rem', fontWeight: 600 }}>12. Governing Law</h1>
                        </Box>
                        <Box>
                            <p>
                                These Terms shall be governed and construed in accordance with the
                                laws of Rajasthan, without regard to its conflict of
                                law provisions. Our failure to enforce any right or provision of
                                these Terms will not be considered a waiver of those rights. If
                                any provision of these Terms is held to be invalid or
                                unenforceable by a court, the remaining provisions of these Terms
                                will remain in effect. These Terms constitute the entire agreement
                                between us regarding our Website, and supersede and replace any
                                prior agreements we might have had between us regarding the
                                Website.
                            </p>
                        </Box>
                        <Box>
                            <h1 style={{ fontSize: '1.2rem', fontWeight: 600 }}>13. Changes to These Terms</h1>
                        </Box>
                        <Box>
                            <p>
                                We reserve the right, at our sole discretion, to modify or replace
                                these Terms at any time. If a revision is material, we will try to
                                provide at least 30 days' notice prior to any new terms taking
                                effect. What constitutes a material change will be determined at
                                our sole discretion. By continuing to access or use our Website
                                after any revisions become effective, you agree to be bound by the
                                revised terms. If you do not agree to the new terms, you are no
                                longer authorized to use the Website.
                            </p>
                        </Box>
                    </Box>
                </Box>
            </Box>

        </>
    )
}

export default TermsAndConditionsMobile
