import React, { useState } from 'react';
import img from '../../../assets/img.jpg';
import Signup1 from '../../../assets/Signup1.png';
import CurveLine from '../../../assets/CurveLine.png';
import wifi2 from '../../../assets/wifi2.png';
import imgs from '../../../assets/imgs.svg';
import LogoHeader from '../../../assets/LogoHeader.svg';
import book from '../../../assets/book.svg';
import bulb from '../../../assets/bulb.svg';
import line from '../../../assets/line.svg';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Backdrop, Button, CircularProgress, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { storeMobile, studentLogin, updateStudentdata } from '../../features/student/studentSlice';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';


const Signup = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isXs = useMediaQuery('(min-width:600px)');
    const [phoneNumber, setPhoneNumber] = useState('');

    const isValidIndianMobileNumber = (mobile) => {
        const indianMobileNumberPattern = /^[6-9]\d{9}$/;
        return indianMobileNumberPattern.test(mobile);
    };

    const handlePhoneNumberChange = (e) => {
        const value = e.target.value;
        // Restrict input to 10 digits
        if (value.length <= 10) {
            setPhoneNumber(value.replace(/\D/, '')); // Remove non-digit characters
        }
    };

    const submitHandler = async (e) => {
        e.preventDefault();
        if (phoneNumber.trim() === '') {
            toast.error('Mobile number is required.');
            return;
        }
        if (phoneNumber.length !== 10) {
            toast.error('Mobile number must be exactly 10 digits.');
            return;
        }
        const isValid = isValidIndianMobileNumber(phoneNumber);
        if (!isValid) {
            toast.error('Please enter a valid Indian mobile number.');
            return;
        }

        dispatch(studentLogin({ mobile: phoneNumber, fcmToken: '', roleId: 2 , created_by:3}))
            .then((apiResponse) => {
                if (apiResponse?.payload?.data) {
                    dispatch(updateStudentdata(apiResponse?.payload?.data))
                    dispatch(storeMobile(phoneNumber))
                    toast.success(apiResponse?.payload?.message);
                    navigate('/signup-otp');
                } else {
                    const errorMessage = apiResponse?.payload?.message
                    toast.error(errorMessage);
                }
            })
            .catch((error) => {
                toast.error(error?.message || "An error occurred while logging in.");
            });
    };

    const handleClose = () => {
        window.location.href = '/';
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            document.getElementById('submitBtn').click();
        }
    };

    const loading = useSelector((state) => state.student.loading)


    return (<>
<Helmet>
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "EducationalOrganization",
            "name": "Home Tuitions in Jaipur",
            "description": Get experienced qualified home tutor, private tutor, persnal tutor near by your area  in Jaipur.",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Jaipur",
              "addressCountry": "IN"
            }
          }
        `}
      </script>
    </Helmet>


    <Helmet>
    <title>Home Tuitions Jaipur</title>
    <meta name="description" content="Get experienced qualified home tutor, private tutor, persnal tutor near by your area  in Jaipur." />
    <meta name="keywords" content="home tuitions in Jaipur, home tutions in Jaipur ,home tutor in jaipur ,home tutors in jaipur , tutoring services" />
    <link rel="canonical" href="https://kanhahometutions.com/home-tutors-jaipur"/>
  </Helmet>

        <div style={{ backgroundImage: `url(${img})`, backgroundSize: 'cover', height: '100vh' }}>

            <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress  color="inherit" />
      </Backdrop>
            <div className='d-flex flex-row justify-content-center align-items-center'>
                <div className='container border-0'>
                    <div className='row justify-content-center'>
                        <div style={{ width: '895px', height: '560px' }}>
                            <div style={{ marginTop: '10%', backgroundColor: '#ffffff' }}>
                                <div className='row'>
                                    <div className='col-md-6' style={{ display: isXs ? '' : 'none' }} >
                                        <div >
                                            <img src={imgs} alt='vector' className='img-fluid' style={{ width: '100%', borderBottomLeftRadius: isXs ? '' : '50px', borderBottomRightRadius: isXs ? '' : '50px' }} />
                                        </div>
                                    </div>
                                    <div className='col-md-6' style={{ display: isXs ? 'none' : '' }}>
                                        <div style={{ backgroundColor: '#CB8B65', height: '20rem', position: 'relative', borderBottomLeftRadius: '20px', borderBottomRightRadius: '20px' }}>
                                            <div className='d-flex justify-content-center'>
                                                <img src={LogoHeader} style={{ position: 'absolute', width: '40%', top: '1rem' }} />
                                                <div style={{ position: 'absolute', color: 'white', top: '5.5rem', border: '2px solid white', borderRadius: '20px', width: '40%', textAlign: 'center' }}>For Students</div>
                                                <img src={Signup1} style={{ position: 'absolute', bottom: 0 }} />
                                            </div>
                                            <img src={wifi2} style={{ position: 'absolute', bottom: 0, left: 5 }} />
                                            <img src={book} style={{ position: 'absolute', top: 0, right: 5 }} />
                                            <img src={CurveLine} style={{ position: 'absolute', top: 0, left: 0 }} />
                                            <img src={CurveLine} style={{ position: 'absolute', bottom: 0, right: 0, rotate: '180deg' }} />
                                        </div>
                                    </div>

                                    <div className='col-md-6' style={{ position: 'relative' }}>
                                        <Button style={{ position: 'absolute', right: '-2%', backgroundColor: 'white', borderRadius: '100%', minWidth: '2rem', color: 'black', marginTop: '-10px' }} onClick={handleClose}>X</Button>
                                        <div className='d-flex flex-row justify-content-between p-2'>
                                            <div>
                                                <h3 className='pt-3' style={{ fontWeight: 'bold' }}>Get Started</h3>
                                                <img src={line} className='mb-4' />
                                            </div>
                                            <img src={book} style={{ height: '12%', width: '12%', display: isXs ? '' : 'none' }} />
                                        </div>
                                        <div>
                                            <form onSubmit={submitHandler}>
                                                <div className="container">
                                                    <div className="row" style={{ fontSize: '14px', marginTop: isXs ? '5%' : '' }}>
                                                        <div className="col-12 my-2">
                                                            <Box
                                                                component="form"
                                                                sx={{
                                                                    '& > :not(style)': { width: '93%' },
                                                                }}
                                                                noValidate
                                                                autoComplete="off"
                                                            >
                                                                <TextField
                                                                    label="Phone number"
                                                                    type='tel'
                                                                    placeholder='Enter your Phone number'
                                                                    value={phoneNumber}
                                                                    onChange={handlePhoneNumberChange}
                                                                    onKeyPress={handleKeyPress} // Handle Enter key press
                                                                    inputProps={{ style: { height: '10px', fontSize: '12px' } }}
                                                                    InputLabelProps={{ sx: { color: 'black' } }}
                                                                    color='grey'
                                                                    focused
                                                                />
                                                            </Box>
                                                        </div>
                                                        <div style={{ width: '100%', textAlign: 'center', marginTop: isXs ? '40%' : '5%' }}>
                                                            <button id="submitBtn" type="submit" className="btn btn-primary py-2 w-100 border-0" style={{ fontSize: '14px', backgroundColor: '#FFDB7E', color: '#866D2C', fontWeight: '600', borderRadius: '0' }}>Next</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                            <div className="d-flex flex-row justify-content-between w-full my-4" style={{ position: 'relative' }}>
                                                <div>
                                                    <img src={bulb} style={{ position: 'absolute', right: '2%', top: '-1rem', display: isXs ? '' : 'none' }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> </>
    );
};

export default Signup;
