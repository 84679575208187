import { Box, Grid, useMediaQuery } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import Footer from "../pages/LandingPage/Footer";
import logo from "../../assets/LogoHeader.svg";
import { Link } from "react-router-dom";
import WhatAppMobile from "../pages/LandingPage/WhatAppMobile";

export const HomeTutors = () => {
  const [siteMapLinks, setSiteMapLinks] = useState([]);

  async function getSiteMapLinks() {
    try {
      const response = await axios.get(
        `https://app.kanhahometutions.com/api/v1/seo-pages`
      );
      var data = response.data.slugs;
      setSiteMapLinks(data);
    } catch (error) {
      console.error("Error fetching slugs:", error);
    }
  }
  useEffect(() => {
    getSiteMapLinks();
    window.scrollTo(0, 0); // Scrolls to the top of the page

  }, []);
  

  const isXs = useMediaQuery('(min-width:600px) and (max-width:3000px)');


  return (
    <>
                          <WhatAppMobile/>

      <div>
        <Box style={{ width: "100%" }}>
          <Box style={{ width: "100%" }}>
            <Box
              style={{
                width: "100%",
                height: "12rem",
                backgroundColor: "#CB8B65",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                padding: "2rem 3rem",
                gap: "1rem",
              }}
            >
             <Link to="/"><img width={200} src={logo} alt="" /></Link> 
              <h1
                style={{
                  fontSize: "3rem",
                  color: "white",
                  textTransform: "uppercase",
                }}
              >
                Sitemap
              </h1>
            </Box>
            <div class="grid-container">
              <Box
                sx={{
                  height: "auto",
                 
                  padding: isXs ? "50px 60px" : '40px 60px',
                  alignItems: "center",
                }}
              >
                <h3 style={{ marginBottom: isXs? "2rem":'1.5rem' }}>Pages</h3>

                <div
                  style={{
                    
                    display: "grid",
                    gridTemplateColumns: isXs ?"repeat(3, 1fr)": 'repeat(1, 1fr)',
                     gap: isXs?'1.3rem':'1rem',
                  }}
                >
                  <Link  style={{textDecoration: 'none', color: 'black'}} to="/">Home</Link>
                  <Link  style={{textDecoration: 'none', color: 'black'}}to="/signin-teacher">Login as teacher</Link>
                  <Link  style={{textDecoration: 'none', color: 'black'}} to="/student-signin">Login as Student</Link>
                  <Link  style={{textDecoration: 'none', color: 'black'}} to="/privacy-policy">Privacy Policy</Link>
                  <Link  style={{textDecoration: 'none', color: 'black'}} to="/refund-policy">Refund Policy</Link>
                  <Link  style={{textDecoration: 'none', color: 'black'}} to="/terms-conditions">Terms & Conditions</Link>
                  <Link  style={{textDecoration: 'none', color: 'black'}} to="/sitemap">Sitemap</Link>
                </div>
              </Box>

              <Box
                sx={{
                  height: "auto",
                  marginBottom: isXs?'15rem':'4rem',
                  padding: "10px 60px",
                  alignItems: "center",
                }}
              >
                <h3 style={{ marginBottom: isXs? "2rem":'1.5rem' }}>Find Tutor</h3>
                <Grid container spacing={2}>
                  {siteMapLinks
                    ? siteMapLinks.map((element) => (
                        <Grid item xs={12} sm={6} md={4} key={element.txt_slug}>
                          <Link
                            style={{
                              textDecoration: "none",
                              color: "black",
                              textTransform: "capitalize",
                            }}
                            to={`/${element.txt_slug.toLowerCase().replaceAll(
                              " ",
                              "-"
                            )}`}
                          >
                            {element.txt_meta_title}
                          </Link>
                        </Grid>
                      ))
                    : console.log("data not found for site map")}
                </Grid>
              </Box>
            </div>
          </Box>
        </Box>
        <Footer />
      </div>
    </>
  );
};
