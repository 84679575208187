import { InputLabel, MenuItem, Select, TextareaAutosize, TextField, useMediaQuery, FormControl, Box } from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import Footer from '../pages/LandingPage/Footer';
import { Link } from 'react-router-dom';
import logo from "../../assets/LogoHeader.svg";


function Institute() {
    const isXs = useMediaQuery('(min-width:600px) and (max-width:3000px)');

    const [name, setName] = useState("");
    const [institutename, setInstitutename] = useState("");
    const [emailaddress, setEmailaddress] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [pinCode, setPinCode] = useState("");
    const [cityName, setCityName] = useState("");
    const [stateName, setStateName] = useState("");
    const [address, setAddress] = useState("");
    const [jobTitle, setJobTitle] = useState("");
    const [jobType, setJobType] = useState("");
    const [instituteType, setInstituteType] = useState("");
    const [jobDescription, setJobDescription] = useState("");
    const [salary, setSalary] = useState("");
    const [min, setMin] = useState("");
    const [max, setMax] = useState("");

    const handleJobType = (e) => setJobType(e.target.value);
    const handleSalary = (e) => setSalary(e.target.value);

    const handleNewFormSubmit = async (e) => {
        e.preventDefault();
        const date = new Date();
        const randomNumber = Math.floor(Math.random() * 1000000);
        const currentDateTime = `${date.getFullYear()}${String(date.getMonth() + 1).padStart(2, '0')}${String(date.getDate()).padStart(2, '0')}${randomNumber}${String(date.getHours()).padStart(2, '0')}${String(date.getMinutes()).padStart(2, '0')}${String(date.getSeconds()).padStart(2, '0')}`;

        const formData = new FormData();
        formData.append("fullName", name);
        formData.append("instituteName", institutename);
        formData.append("email", emailaddress);
        formData.append("phone", phoneNumber);
        formData.append("pincode", pinCode);
        formData.append("city", cityName);
        formData.append("state", stateName);
        formData.append("address", address);
        formData.append("jobTitle", jobTitle);
        formData.append("jobType", jobType);
        formData.append("instituteType", instituteType);
        formData.append("description", jobDescription);
        formData.append("salaryType", salary);
        formData.append("minSalary", min);
        formData.append("maxSalary", max);
        formData.append("token", currentDateTime);

        try {
            const response = await axios.post("https://app.kanhahometutions.com/api/v1/demoRequestInstitute", formData, {
                headers: { "content-type": "multipart/form-data" },
            });
            toast.success(response.data.message);
        } catch (error) {
            toast.error(error?.response?.data?.message ?? "An error occurred. Please try again.");
        }
    }

    return (<>

<Box
              style={{
                width: "100%",
                height: "12rem",
                backgroundColor: "#CB8B65",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                padding: "2rem 3rem",
                gap: "1rem",
              }}
            >
             <Link to="/"><img width={200} src={logo} alt="" /></Link> 
              <h1
                style={{
                  fontSize: "2rem",
                  color: "white",
                  textTransform: "uppercase",
                }}
              >
Post a Teacher Job Opening              </h1>
            </Box>
        <div style={{ display: 'flex', margin:'70px 0px', flexDirection:isXs? "":'column'}}>
            {/* Left Side Box */}
            <div style={{ paddingLeft:"50px", width: isXs?  '45%':'100%',  display:'flex',gap:'30px', flexDirection:'column'}}>
<div style={{fontSize:'40px', color:'#774c33', fontWeight:'bold'}}>Why Kanha Home Tutions?</div>
<div style={{color:'black', fontSize:'16px', fontWeight:'500', width:'500px'}}>Finding qualified, passionate teachers can be tough. Kanha Home Tutions makes it easy with a job portal for efficient hiring. Schools can post jobs and find candidates tailored to their needs across India.</div>
<div style={{display:'flex', flexDirection:'column', gap:'10px'}}><div style={{fontSize:'22px', fontWeight:'bold' }}>Our Purpose</div> 
<div style={{display:'flex', flexDirection:'column', gap:'3px'}}> 
<li style={{fontSize:'16px', fontWeight:'500'}}>Simplify and optimize teacher recruitment.</li>
<li style={{fontSize:'16px', fontWeight:'500'}}>Offer a comprehensive service with personal assistance.</li>
<li style={{fontSize:'16px', fontWeight:'500'}}>Use efficient technology to streamline hiring.</li>
<li style={{fontSize:'16px', fontWeight:'500'}}>Provide tools for schools to hire the best teachers quickly.</li>
</div>
</div>
<div style={{display:'flex', flexDirection:'column', gap:'10px'}}><div style={{fontSize:'22px', fontWeight:'bold'}}>What We Offer</div> 
<div style={{display:'flex', flexDirection:'column', gap:'3px'}}>
<li style={{fontSize:'16px', fontWeight:'500'}}>Easy, stress-free teacher recruitment.</li>
<li style={{fontSize:'16px', fontWeight:'500'}}>Simple job posting process.</li>
<li style={{fontSize:'16px', fontWeight:'500'}}>Continuous support throughout the hiring process.</li>
<li style={{fontSize:'16px', fontWeight:'500'}}>Receive instant alerts for new applications.</li>
</div>
</div>
<div style={{display:'flex', flexDirection:'column', gap:'10px'}}><div style={{fontSize:'22px', fontWeight:'bold'}}>Simplified Job Posting</div> 
<div style={{display:'flex', flexDirection:'column', gap:'3px'}}>
<li style={{fontSize:'16px', fontWeight:'500'}}>Easy job posting process.</li>
<li style={{fontSize:'16px', fontWeight:'500'}}>Our system matches you with qualified candidates.</li>
<li style={{fontSize:'16px', fontWeight:'500'}}>Receive candidate profiles directly in your email inbox.</li>
</div>
</div>                        </div>

            {/* Right Side Form */}
            <div style={{  width: isXs?  '55%':'100%',  }}>
                <form onSubmit={handleNewFormSubmit} style={{ padding: isXs ? "20px 60px" : "0px", }}>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '15px', backgroundColor: 'white', padding: '20px', borderRadius: '10px', boxShadow: '1px 1px 15px rgb(10, 5, 19, .3)' }}>
                        <div style={{ textAlign: 'start', fontSize: '16px', fontWeight: '500' }}>School / Institute Basic Information</div>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '25px' }}>
                            <div style={{ display: 'flex', gap: '20px' }}>
                                <TextField label="Full Name" required onChange={(e) => setName(e.target.value)} inputProps={{ style: { height: "10px", fontSize: "12px" } }} InputLabelProps={{ sx: { color: "black" } }} color="grey" focused fullWidth />
                                <TextField label="School / Institute Name" required onChange={(e) => setInstitutename(e.target.value)} inputProps={{ style: { height: "10px", fontSize: "12px" } }} InputLabelProps={{ sx: { color: "black" } }} color="grey" focused fullWidth />
                            </div>
                            <div style={{ display: 'flex', gap: '20px' }}>
                                <TextField label="Email Address" type="email" required onChange={(e) => setEmailaddress(e.target.value)} inputProps={{ style: { height: "10px", fontSize: "12px" } }} InputLabelProps={{ sx: { color: "black" } }} color="grey" focused fullWidth />
                                <TextField label="Phone Number" type="tel" required value={phoneNumber} onChange={(e) => { const value = e.target.value; if (/^\d{0,10}$/.test(value)) { setPhoneNumber(value); } }} inputProps={{ style: { height: "10px", fontSize: "12px" } }} InputLabelProps={{ sx: { color: "black" } }} color="grey" focused fullWidth />
                            </div>
                            <TextField label="Address" required onChange={(e) => setAddress(e.target.value)} inputProps={{ style: { height: "10px", fontSize: "12px" } }} InputLabelProps={{ sx: { color: "black" } }} color="grey" focused fullWidth />
                            <div style={{ display: 'flex', gap: '20px' }}>
                                <TextField label="Pin Code" required type="number" value={pinCode} onChange={(e) => { const value = e.target.value; if (/^\d{0,6}$/.test(value)) { setPinCode(value); } }} inputProps={{ style: { height: "10px", fontSize: "12px" } }} InputLabelProps={{ sx: { color: "black" } }} color="grey" focused fullWidth />
                                <TextField label="City" required onChange={(e) => setCityName(e.target.value)} inputProps={{ style: { height: "10px", fontSize: "12px" } }} InputLabelProps={{ sx: { color: "black" } }} color="grey" focused fullWidth />
                                <TextField label="State" required onChange={(e) => setStateName(e.target.value)} inputProps={{ style: { height: "10px", fontSize: "12px" } }} InputLabelProps={{ sx: { color: "black" } }} color="grey" focused fullWidth />
                            </div>
                        </div>
                        <div style={{ fontSize: '16px', fontWeight: '500' }}>Post a New Job</div>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '25px' }}>
                            <TextField label="Job Title" required onChange={(e) => setJobTitle(e.target.value)} inputProps={{ style: { height: "10px", fontSize: "12px" } }} InputLabelProps={{ sx: { color: "black" } }} color="grey" focused fullWidth />
                            <div style={{ display: 'flex', gap: '20px' }}>
                                <FormControl required fullWidth>
                                    <InputLabel id="job-type-label" style={{ fontSize: '12px' }}>Job Type</InputLabel>
                                    <Select labelId="job-type-label" value={jobType} onChange={handleJobType} style={{ height: '45px', fontSize: '12px' }} label="Job Type">
                                        <MenuItem value='Full Time'>Full Time</MenuItem>
                                        <MenuItem value='Part Time'>Part Time</MenuItem>
                                        <MenuItem value='Online Teaching'>Online Teaching</MenuItem>
                                    </Select>
                                </FormControl>
                                <TextField label="Institute Type" required onChange={(e) => setInstituteType(e.target.value)} inputProps={{ style: { height: "10px", fontSize: "12px" } }} InputLabelProps={{ sx: { color: "black" } }} color="grey" focused fullWidth />
                            </div>
                            <TextareaAutosize aria-label="Job Description" placeholder="Job Description" required onChange={(e) => setJobDescription(e.target.value)} style={{ height: "50px", fontSize: "12px", width: '100%', border: '2px solid #C4C4C4', borderRadius: '4px', padding: '8px', outline: 'none', }} />
                            <div style={{ display: 'flex', gap: '20px' }}>
                                <FormControl required fullWidth>
                                    <InputLabel id="salary-label" style={{ fontSize: '12px' }}>Salary</InputLabel>
                                    <Select labelId="salary-label" value={salary} onChange={handleSalary} style={{ height: '45px', fontSize: '12px' }} label="Salary">
                                        <MenuItem value='Year'>Year</MenuItem>
                                        <MenuItem value='Month'>Month</MenuItem>
                                        <MenuItem value='Week'>Week</MenuItem>
                                        <MenuItem value='Hour'>Hour</MenuItem>
                                    </Select>
                                </FormControl>
                                <TextField label="Min" type="number" value={min} required onChange={(e) => { const value = e.target.value; if (/^\d{0,11}$/.test(value)) { setMin(value); } }} inputProps={{ style: { height: "10px", fontSize: "12px" } }} InputLabelProps={{ sx: { color: "black" } }} color="grey" focused fullWidth />
                                <TextField label="Max" type="number" value={max} required onChange={(e) => { const value = e.target.value; if (/^\d{0,11}$/.test(value)) { setMax(value); } }} inputProps={{ style: { height: "10px", fontSize: "12px" } }} InputLabelProps={{ sx: { color: "black" } }} color="grey" focused fullWidth />
                            </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin:'25px 0px' }}>
                            <button type="submit" style={{ backgroundColor: '#CB8B65', color: 'white', width: '100%', height: '2.5rem', border: 'none', borderRadius: '3px', fontSize: '16px', cursor: 'pointer', }}>
                                Submit
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <Footer/>
        </>
    );
}

export default Institute;
