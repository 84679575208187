import React from "react";
import Tutor2 from "../../../assets/Tutor2.svg";
import pencil2 from "../../../assets/pencil2.svg";
import bulb2 from "../../../assets/bulb2.svg";
import calculater from "../../../assets/calculater.svg";
import DottedArrow from "../../../assets/DottedArrow.svg";
import line2 from "../../../assets/line2.svg";
import line3 from "../../../assets/line3.svg";
import Group2081 from "../../../assets/Group2081.svg";
import Group35 from "../../../assets/Group35.svg";
import Group36 from "../../../assets/Group36.svg";
import arrow2 from "../../../assets/arrow2.svg";
import Scale from "../../../assets/Scale.svg";
import Frame from "../../../assets/Frame.svg";
import Leaderstyle from "../../../assets/Leaderstyle.svg";
import Leaderstyle2 from "../../../assets/Leaderstyle2.svg";
import Leaderstyle3 from "../../../assets/Leaderstyle3.svg";
import Pin1 from "../../../assets/Pin1.svg";
import Pin2 from "../../../assets/Pin2.svg";
import Pin3 from "../../../assets/Pin3.svg";
import Frame24 from "../../../assets/Frame24.svg";
import Frame25 from "../../../assets/Frame25.svg";
import GooglePlay from "../../../assets/GooglePlay.svg";
// import AppStore from "../../../assets/AppStore.svg";
import location2 from "../../../assets/location2.svg";
import Phone from "../../../assets/Phone.svg";
import Email from "../../../assets/Email.svg";
import Group92 from "../../../assets/Group92.svg";
import LogoHeader from "../../../assets/LogoHeader.svg";
import Group21 from "../../../assets/Group21.png";
import LogoFooter from "../../../assets/LogoFooter.svg";
import GroupPhone from "../../../assets/mobile-screen 2.svg";
import PhoneDoodlePlus from "../../../assets/PhoneDoodlePlus.svg";
import PhoneDoodleMultiply from "../../../assets/PhoneDoodleMultiply.svg";
import PhoneDoodleBook from "../../../assets/PhoneDoodleBook.svg";
import PhoneDoodleDivide from "../../../assets/PhoneDoodleDivide.svg";
import FooterBack from "../../../assets/FooterBack.svg";
import Group2110 from "../../../assets/Group2110.svg";
import Group2111 from "../../../assets/Group2111.svg";
import Group221 from "../../../assets/Group221.svg";
import NoteBook from "../../../assets/NoteBook.svg";
import Globe from "../../../assets/Globe.svg";
import Step1 from "../../../assets/Step1.svg";
import Step2 from "../../../assets/Step2.svg";
import Step3 from "../../../assets/Step3.svg";
import whatsappicon from "../../../assets/floatingwhatsappicon.png";
import phoneicon from "../../../assets/floatingphoneicon.png";
import headerimg from "../../../assets/Header-img.png";
import class1_6 from "../../../assets/1-6.jpg";
import class7 from "../../../assets/7.jpg";
import class8 from "../../../assets/8.jpg";
import class9 from "../../../assets/9.jpeg";
import class10 from "../../../assets/10.jpg";
import class11 from "../../../assets/11.jpg";
import class12 from "../../../assets/12.jpg";
import IIT from "../../../assets/IIT.jpeg";
import Neet from "../../../assets/Neet.jpeg";
import NDA from "../../../assets/NDA.jpg";
import QuicklinkBg from "../../../assets/QuicklinkBG.svg";
import ArrowNew from "../../../assets/ArrowNew.svg";
import Linenew from "../../../assets/Linenew.svg";
import Postteacher from "../../../assets/Postteacher.jpeg";


import ReCAPTCHA from "react-google-recaptcha";
import { TextareaAutosize } from '@mui/base';

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import line4 from "../../../assets/line4.svg";
import cloudshape from '../../../assets/cloudshape.svg'
import teacherteaching from '../../../assets/teacherteaching.svg'
import Stack from "@mui/material/Stack";
import { useState, useEffect ,useRef } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { styled, css, style, padding } from "@mui/system";
import { Modal as BaseModal } from "@mui/base/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Container, Grid, Typography, Button, Dialog, IconButton, InputLabel, Select, MenuItem, OutlinedInput } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import axios from "axios"
import { toast, ToastContainer } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import CircularProgress from "@mui/material/CircularProgress";
import { yupResolver } from "@hookform/resolvers/yup";
import Footer from "./Footer";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet';
import WhatAppMobile from "./WhatAppMobile";
import { Description } from "@mui/icons-material";
import { Dropdown } from 'react-bootstrap';



const themeColors = ["#ff5733", "#3498db", "#2ecc71"];

const Landing = () => {
  
  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "Kanha Home Tutions",
    "url": "https://www.kanhahometutions.com/",
    "logo": "https://www.kanhahometutions.com/static/media/LogoHeader.5eb7d516de8b950fe8230235cda93e37.svg",
    "contactPoint": {
      "@type": "ContactPoint",
      "telephone": "+91 6376400386",
      "contactType": "customer service",
      "contactOption": "TollFree",
      "areaServed": "IN",
      "availableLanguage": ["Hindi", "en"]
    },
    "sameAs": [
      "https://x.com/kanhahometution?s=21",
      "https://www.instagram.com/kanha_home_tuition?igsh=c29rOWFyY29wdm5m",
      "https://www.youtube.com/@Kanhahome",
      "https://pin.it/6ei6C46br",
      "https://www.tumblr.com/kanhahometutions",
      "https://www.kanhahometutions.com/"
    ]
  };

  
  const navigate = useNavigate();
  const schema = yup.object().shape({
    email: yup.string().email().required(),
    fullName: yup.string().required(),
    pincode: yup.string().required(),
    area: yup.string().required(),
    city: yup.string().required(),
    mobileNumber: yup.string().required(),
    subject: yup.string().required(),
    course: yup.string().required(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      classMode: "2",
    },
  }); 
  
 
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = (reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false)
    }
  }
  const [selectedColor, setSelectedColor] = useState("");
  const [loading, setLoading] = useState(false);

  const onSubmitHandler = async (data) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("email", data.email);
    formData.append("area", data.area);
    formData.append("full_name", data.fullName);
    formData.append("pincode", data.pincode);
    formData.append("course", data.course);
    formData.append("subject", data.subject);
    formData.append("class_mode", data.classMode);
    formData.append("city", data.city);
    formData.append("phone", data.mobileNumber);

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };

    try {
      const response = await axios.post(
        "https://kanhahometutions.com/api/demo_request_new.php",
        formData,
        config
      );

      if (response.status === 200) {
        toast.success(response.data.message);
        setLoading(false);
        setOpen(false);
        reset();
      } else {
        toast.warning(response.data.message ?? "Something Went Wrong");
        setLoading(false);
      }
    } catch (error) {
      toast.error(
        error?.response.data.message ?? "An error occurred. Please try again."
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * themeColors.length);
    setSelectedColor(themeColors[randomIndex]);
  }, []);


  const [quickLinks, setQuickLinks] = useState([]);

  async function getQuickLinks() {
    try {
      const response = await axios.get(`https://app.kanhahometutions.com/api/v1/quick-links`);

      setQuickLinks(response.data);

    } catch (error) {
      console.error("Error fetching:", error);
    }
  }
  
  useEffect(() => {
    getQuickLinks();
  }, []);
  


  const sxStyle = {
    display: 'flex',
    justifyContent: { xs: 'center' },
    alignItems: 'center',
  };

 const isXs = useMediaQuery('(min-width:600px) and (max-width:3000px)');
  
  const markerStyle = {
    color: '#B64502',
  } 

  const [isOpen, setIsOpen] = useState(false);
  const sidebarRef = useRef(null);

  const [selectedText, setSelectedText] = useState('');
  const [seoPages, setSeoPages] = useState([]);


  const toggleSidebar = (text, seoPages) => {
      setIsOpen((prev) => !prev);
      setSelectedText(text);
      setSeoPages(seoPages); 
  };


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setIsOpen(false);
      } 
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const styles = {
    grid: {
      display: 'grid',
      gridTemplateColumns: isXs? 'repeat(3, 1fr)' :'repeat(1, 1fr)',
      gap: '20px',
    },
    box: {
      display: 'flex',                     
      justifyContent: 'space-between',            
      alignItems: 'center',                
      padding: '20px',
      backgroundColor: '#FFEBE2',
      border: '1px solid #ccc',
      borderRadius: '7px',
      boxShadow: '7px 7px 1px rgba(10, 10, 10, 0.17)',
      height: '3.7rem',
      width: '18rem',
      fontWeight: '500',
      cursor:'pointer',
      textAlign: 'left',                 

    },
  };

  
  

  const [openNew, setOpenNew] = useState(false);
  const handleClickOpenNew = () => {
    setOpenNew(true);
  };

  const handleCloseNew = () => {
    setOpenNew(false);
  };

  const handleJobType = (e) => {
    setJobType(e.target.value);
  };

  const handlesalary = (e) => {
    setSalary(e.target.value);
  };

  const [name, setName] = useState("");
  const [ institutename ,setInstitutename] = useState("")
  const [ emailaddress ,setEmailaddress] = useState("")
  const [ phoneNumber ,setPhoneNumber] = useState("")
  const [ pinCode ,setPinCode] = useState("")
  const [ cityName ,setCityName] = useState("")
  const [ stateName ,setStateName] = useState("")
  const [ address ,setAddress] = useState("")
  const [ jobTitle ,setJobTitle] = useState("")
  const [jobType, setJobType] = useState("");
  const [ instituteType ,setInstituteType] = useState("")
  const [ jobDescription ,setJobDescription] = useState("")
  const [salary, setSalary] = useState("");
  const [min, setMin] = useState("");
  const [max, setMax] = useState("");
  const [error, setError]= useState("")  
 
  const handleNewformsubmit = async (e) =>{
    e.preventDefault();
   
  let date = new Date;
  let randomnumber = Math.floor((Math.random() * 1000000));
  let year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
  let month = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(date);
  let day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
  let hours = new Intl.DateTimeFormat('en', { hour: '2-digit', hour12: false }).format(date);
  let minutes = new Intl.DateTimeFormat('en', { minute: '2-digit' }).format(date);
  let seconds = new Intl.DateTimeFormat('en', { second: '2-digit' }).format(date);

  let CurrentDateTime = (`${year}${month}${day}${randomnumber}${hours}${minutes}${seconds}`);
    
const formDatanew = new FormData();
formDatanew.append("fullName", name );
formDatanew.append("instituteName", institutename);
formDatanew.append("email", emailaddress);
formDatanew.append('phone',phoneNumber)
formDatanew.append('pincode',pinCode)
formDatanew.append('city',cityName)
formDatanew.append('state',stateName)
formDatanew.append('address',address)
formDatanew.append('jobTitle',jobTitle)
formDatanew.append('jobType',jobType)
formDatanew.append('instituteType',instituteType)
formDatanew.append('description',jobDescription)
formDatanew.append('salaryType',salary)
formDatanew.append('minSalary',min)
formDatanew.append('maxSalary',max)
formDatanew.append('token', CurrentDateTime);


const config = {
  headers: { "content-type": "multipart/form-data" },
};

  // const response = await axios.post('https://app.kanhahometutions.com/api/v1/demoRequestInstitute', formDatanew );
  // console.log("respos",response.data);
  try {
    const response = await axios.post(
      "https://app.kanhahometutions.com/api/v1/demoRequestInstitute",
      formDatanew,
      config
    );

    if (response.status === 200) {
      toast.success(response.data.message);
      // toast.success(response.data)
      // setLoading(false);
      setOpenNew(false);
      reset();
    } else {
      toast.warning(response.data.message ?? "Something Went Wrong");
      // setLoading(false);
    }
  } catch (error) {
    toast.error(
      error?.response.data.message ?? "An error occurred. Please try again."
    );
    // setLoading(false);
  }

  }
  
  return (
    <>

<Helmet>
        <script type="application/ld+json">
          {JSON.stringify(jsonLd)}
        </script>
      </Helmet>

      <WhatAppMobile/>

<div
  style={{
    width: "100%",
    height: '100vh',
    position: "relative",
    backgroundColor: isXs ? "" : "#CB8B65",
    height: isXs ? "" : "61rem",
  }}
>
  <img
    style={{ 
      width: '100%', 
      display: isXs ? "" : "none", 
      // objectFit: 'cover' 
    }}
    src={headerimg}
    alt=""
  />
</div>


      <div 
      style={{

        position: "absolute",
        left: "0",
        right: "0",
        bottom: '45px',
        marginInline: "auto",
        width: "100%",
        display: isXs ? '' : 'none'
      }}>
      </div>

      <div
        style={{
          position: "absolute",
          left: "0",
          right: "0",
          top: "2%",
          marginInline: "auto",
          width: "100%",
          padding: isXs ? "1rem 6rem" : "0rem",
          display: "flex",
          flexDirection: "column",
          gap: isXs ? "2rem" : "3rem",
          justifyContent: isXs ? "" : "center",
          alignItems: isXs ? "" : "center",
        }}
      >

        <div style={{display: 'flex', justifyContent: 'space-between', flexDirection: isXs ? '' : 'column', gap: isXs ? "" : "1rem", alignItems:'center'}}>
          <img width={200} src={LogoHeader} alt="" />
          <h3
              className="text-white"
              style={{
                fontSize: isXs ? "1.5vw" : "3.8vw",
                display:isXs? 'none':''
              }}
            >
              Welcome to Kanha Home Tutions
            </h3>
          <div
            style={{
              display: "flex",
              flexDirection: isXs ? "row" : "column",
              gap: "1rem",
              justifyContent: 'center',
              alignItems: 'center'
              
              
            }}
          >

<Dropdown align="start" drop="center">
  <Dropdown.Toggle
    style={{
      display: isXs ? '' : 'none',
      backgroundColor: '#D09775',
      color: 'white',
      border: '2px white solid',
      fontSize: '1rem',
      fontWeight: '500',
      padding: '3px 13px',
    }}
  >
    One to One Class
  </Dropdown.Toggle>
  <Dropdown.Menu
    style={{
      fontSize: '12px',
    
    }}
  >
    <Dropdown.Item onClick={handleOpen}>Online Tuition</Dropdown.Item>
    <Dropdown.Item onClick={handleOpen}>Home Tuition</Dropdown.Item>
    <Dropdown.Item onClick={handleOpen}>Hire a Personal Tutor</Dropdown.Item>
  </Dropdown.Menu>
</Dropdown>



<Dropdown align="start" drop="center">
<Dropdown.Toggle style={{ display: isXs ? '':'none', backgroundColor: '#D09775', color: 'white', border:'2px white solid',fontSize: "1rem",fontWeight:"500", padding:'3px 13px' }}>
  Online Courses
</Dropdown.Toggle>
  <Dropdown.Menu>
    <Dropdown.Item style={{fontSize:'12px'}} onClick={handleOpen}> Online Class IIT-JEE maths</Dropdown.Item>
    <Dropdown.Item style={{fontSize:'12px'}} onClick={handleOpen}> Online class IIT-JEE physics</Dropdown.Item>
    <Dropdown.Item style={{fontSize:'12px'}} onClick={handleOpen}> Online class IIT-JEE chemistry </Dropdown.Item>
    <Dropdown.Item style={{fontSize:'12px'}} onClick={handleOpen}> Online class NEET physics</Dropdown.Item>
    <Dropdown.Item style={{fontSize:'12px'}} onClick={handleOpen}> Online class NEET chemistry </Dropdown.Item>
    <Dropdown.Item style={{fontSize:'12px'}} onClick={handleOpen}> Online class NEET biology</Dropdown.Item>
    <Dropdown.Item style={{fontSize:'12px'}} onClick={handleOpen}> Online class 12 th maths CBSE</Dropdown.Item>
    <Dropdown.Item style={{fontSize:'12px'}} onClick={handleOpen}> Online class 12 physics </Dropdown.Item>
    <Dropdown.Item style={{fontSize:'12px'}} onClick={handleOpen}> Online class 12 chemistry</Dropdown.Item>
    <Dropdown.Item style={{fontSize:'12px'}} onClick={handleOpen}> Online class 12 th biology</Dropdown.Item>
  </Dropdown.Menu>
</Dropdown>
            <button
              style={{
                width: "11rem",
                height: "3.5rem",
                border: "3px solid white",
                backgroundColor: "rgb(255,255,255 ,.1)",
                color: "white",
                borderRadius: "20rem",
                fontSize: "1rem",
                fontWeight: "500",
                boxShadow: "4px 7px rgb(000,000,000 ,.1)  ",
              }}
            >
              <Link
                to="/student-signin"
                style={{
                  color: "white",
                  textDecoration: "none", 
                }}
              >
                Login as Student
              </Link>
            </button>
            <button
              style={{
                width: "11rem",
                height: "3.5rem",
                border: "3px solid white",
                backgroundColor: "rgb(255,255,255 ,.1)",
                color: "white",
                borderRadius: "20rem",
                fontSize: "1rem",
                fontWeight: "500",
                boxShadow: "4px 7px rgb(000,000,000 ,.1)  ",
              }}
            >
              <Link
                to="/signin-teacher"
                style={{ color: "white", textDecoration: "none" }}
              >
                Login as Tutor
              </Link>
            </button>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: isXs ? "" : "column",
          }}
        >
          <div
            style={{ display: "flex", flexDirection: "column", gap: isXs?  "1rem" :'2rem',width: isXs? '50%': '', justifyContent: isXs ? "" : "center",  alignItems: isXs ? "" : "center" }}
          >
            <h3
              className="text-white"
              style={{
                fontSize: isXs ? "1.5vw" : "3.8vw",
                display:isXs? '':'none'
              }}
            >
              Welcome to Kanha Home Tutions
            </h3>

            <h1
              className="text-white fw-bold"
              style={{
                // fontSize: isXs ? "3vw" : "6.6vw",
                lineHeight: isXs ?  "3rem" : "1.8rem",
                textAlign: isXs ? '' : 'center'
              }}
            >
          Best Tutor for <br /> Home and Online Tutions    
         </h1>

            <img src={line2} style={{ width: "25%", maxWidth: "200px" }} />
            <div
              className="text-white"
              style={{ width: isXs ? "100%" : '70%', fontSize: isXs ? "1vw" : "3.5vw",
                textAlign: isXs ? '' : 'center'

               }}
            >
           <span>   Connect with expert tutors for online or home tuitions, tailored to your learning needs and personal style. We offer classes for IIT-JEE, NEET, NDA, 12th, 11th, and all subjects across all grades, available all over India.</span>
            </div>

            <button
              className="py-2 px-2"
              onClick={handleOpen}
              type="button"
              style={{
                backgroundColor: "#FFDB7E",
                border: "none",
                color: "#866D2C",
                borderRadius: "5px",
                fontWeight: "bold",
                fontSize: isXs ? "1.5rem" : "1.5rem",
                width: isXs ? "80%" : "23rem",
                height: isXs ? "4rem" : "5rem",
                boxShadow: "5px  5px 2px rgb(0,0,0, .2)",
              }}
            >
Get a Free Demo Class
              <img
                src={DottedArrow}
                style={{
                  width: "14%",
                  maxWidth: "25px",
                  marginLeft: "5%",
                }}
              />
            </button>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: isXs ?  "end" : 'center',
              alignItems: "center",
              marginTop: isXs ? '' : '2rem'
            }}
          >
            <img style={{ width: "90%" }} src={Tutor2} />
          </div>
        </div>
      </div>

      <Grid container style={{ position: "relative", backgroundColor: isXs ? 'transparent' : '#F6EBDC' }} sx={sxStyle}>

        <svg
          width="1549"
          height="1041"
          viewBox="0 0 1549 1041"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            position: "absolute",
            top: isXs ? '-25%' : '0%',
            left: 0,
            width: "100%",
            height: "auto",
            zIndex: -2,
            display: isXs ? '' : 'none'
          }}
        >
          <path
            d="M1548.23 0.310059H0.0720825V872.674C81.19 1035.96 492.48 983.73 773.672 956.998C979.637 937.417 1321.45 930.627 1548.23 1040.54V0.310059Z"
            fill="#F6EBDC"
          />
        </svg>
        <img src={Group2110} style={{ position: 'absolute', zIndex: -1, bottom: '-20%', display: isXs ? '' : 'none', width: '98%' }} />
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} >
          <div className="d-flex justify-content-center">
            <div className="text-center" style={{ marginTop: isXs ? "5.5%" : '12%' }}>
              <div
                style={{
                  color: "#A6653F",
                  fontSize: isXs ? "3vw" : '6vw',
                  fontWeight: "bold",
                }}
              >
Looking Teachers for <br style={{display:isXs? 'none':''}} /> Schools & Institute ?           </div>
              <img src={Group2081} style={{ width: isXs ? "80%" : '90%', marginTop: isXs ? "10%" : '12%' }} />
              <div className="text-center" style={{ marginTop: isXs ? "10%" : '12%', marginBottom: isXs ? '' : '40%' }}>
                <button
                  className="py-2"
                  //  onClick={handleOpen}
                  onClick={handleClickOpenNew}
                  type="button"
                  style={{
                    backgroundColor: "#FFDB7E",
                    width: isXs ? "55%" : '90%',
                    height: isXs ? "5rem" : '6rem',
                    border: "1px solid #CB8B65",
                    color: "#866D2C",
                    fontSize: isXs ? "1.2vw" : '4.5vw',
                    fontWeight: "bold",
                    boxShadow: '5px  5px 2px rgb(0,0,0, .2)'
                  }}
                >START HIRING TEACHERS
                  <img
                    src={arrow2}
                    style={{
                      width: "9%",
                      maxWidth: "30px",
                      marginLeft: isXs ? "8%" : '3%',
                    }}
                  />
                </button>
                <Dialog
            open={openNew}
            onClose={() => {}}
            maxWidth="md"
            sx={{
              '& .MuiBackdrop-root': {
                backdropFilter: 'blur(1px)', // Apply blur effect directly
              },
            }}
      
            PaperProps={{
              sx: {
                padding: "rem",
                overflowX: "hidden",
              },
            }}
          >
             <div
                     className="text-center py-2 fw-bold"
                      style={{
                        position:'relative',
                        backgroundColor: '#CB8B65',
                        width: "100%",
                        height: isXs ? '':'80px',
                        fontSize: isXs ? "28px" : "18px",
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems:'center',
                        gap:isXs ?  '0.5rem':'0.3rem',
                        borderTopLeftRadius: '5px',
                        borderTopRightRadius: '5px'
                      }}
                    >

<Button onClick={handleCloseNew} style={{ position: 'absolute', right: '0%', top:'0',  backgroundColor: 'white', borderRadius: '100%', minWidth: '2rem', color: 'black' }} >X</Button>
                      <div
                        style={{
                          color: "#FFDB7E",

                        }}
                      >
                        Provide
                      </div>
                      <div
                        style={{
                          color: "white",
                        }}
                      >
Your Recruitment Needs                      </div>

                    </div>
          

           <div style={{padding:'1rem'}}>
            <form onSubmit={handleNewformsubmit} style={{ padding: isXs? "0px 10px":"0px 0px" }}>
                   
  <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
  <div style={{textAlign:'start', fontSize:'16px', fontWeight:'500' }}>School / Institute Basic Information</div>
    
    <div style={{ display: 'flex', gap: isXs ? '20px':'7px' }}>
      <TextField
        name="fullname"
        label="Full Name"
        type="text"
        placeholder="Enter your Full Name"
        required
        onChange={(e) => setName(e.target.value)}
        inputProps={{
          style: { height: "10px", fontSize: "12px", width: '14.7rem' },
        }}
        InputLabelProps={{ sx: { color: "black" } }}
        color="grey"
        focused
      />
      <TextField
        name="institutionname"
        label="School / Institute Type"
        type="text"
        placeholder="Enter your Institution Name"
        required
        onChange={(e) => setInstitutename(e.target.value)}
        inputProps={{
          style: { height: "10px", fontSize: "12px", width: '14.7rem' },
        }}
        InputLabelProps={{ sx: { color: "black" } }}
        color="grey"
        focused
      />
    </div>

    <div style={{ display: 'flex', gap:isXs? '20px':'7px' }}>
      <TextField
        name="emailaddress"
        label="Email Address"
        type="email"
        placeholder="Enter Email Address"
        required
        onChange={(e) => setEmailaddress(e.target.value)}
        inputProps={{
          style: { height: "10px", fontSize: "12px", width: '14.7rem' },
        }}
        InputLabelProps={{ sx: { color: "black" } }}
        color="grey"
        focused
      />
      <TextField
        name="phonenumber"
        label="Phone Number"
        type="number"
         value={phoneNumber}
        placeholder="Enter Phone Number"
        required
        onChange={(e) =>{ 
          const value = e.target.value;
    if (/^\d{0,10}$/.test(value)) {
     return setPhoneNumber(value);
    }
  }}
        inputProps={{
          style: { height: "10px", fontSize: "12px", width: '14.7rem', 
                  },
        }}
        InputLabelProps={{ sx: { color: "black" } }}
        color="grey"
        focused
      />
    </div>
    
   
    
    <TextField
      name="address"
      label="Address"
      type="text"
      placeholder="Enter Your Address"
      required
      onChange={(e)=>setAddress(e.target.value)}
      inputProps={{
        style: { height: "10px", fontSize: "12px", width: '32.5rem' },
      }}
      InputLabelProps={{ sx: { color: "black" } }}
      color="grey"
      focused
    />

<div style={{ display: 'flex', gap: isXs ? '20px':'7px' }}>
      <TextField
        name="pincode"
        label="Pin Code"
        type="number"
        value={pinCode}
        placeholder="Pin Code"
        required
        onChange={(e) => {      const value = e.target.value;
          if (/^\d{0,6}$/.test(value)) {
           return setPinCode(value);
          }}}
        inputProps={{
          style: { height: '10px', fontSize: '12px', width: '7rem' },
        }}
        InputLabelProps={{ sx: { color: "black" } }}
        color="grey"
        focused
      />
      <TextField
        name="city"
        label="City"
        type="text"
        placeholder="City"
        required
        onChange={(e)=>setCityName(e.target.value)}
        inputProps={{
          style: { height: "10px", fontSize: "12px", width: '10rem' },
        }}
        InputLabelProps={{ sx: { color: "black" } }}
        color="grey"
        focused
      />
      <TextField
        name="state"
        label="State"
        type="text"
        placeholder="State"
        required
        onChange={(e)=>setStateName(e.target.value)}
        inputProps={{
          style: { height: "10px", fontSize: "12px", width: '10rem' },
        }}
        InputLabelProps={{ sx: { color: "black" } }}
        color="grey"
        focused
      />
    </div>
    <div style={{fontSize:'16px',fontWeight:'500' }}>Post a New Job</div>

    <div style={{ display:  'flex', gap: '20px',  flexDirection: isXs ? '':'column'}}>
      <TextField
        name="jobtitle"
        label="Job Title"
        type="text"
        placeholder="Example: English Teacher"
        required
        onChange={(e)=>setJobTitle(e.target.value)}
        inputProps={{
          style: { height: "10px", fontSize: "12px", width: '10rem' },
        }}
        InputLabelProps={{ sx: { color: "black" } }}
        color="grey"
        focused
      />
      <FormControl required sx={{ minWidth: 140, maxHeight: 20 }}>
        <InputLabel style={{ fontSize: '12px' }} id="job-type-label">Job Type</InputLabel>
        <Select
          labelId="job-type-label"
          id="job-type-select"
          name="jobtype"
          value={jobType}
          style={{ height: '45px', fontSize: '12px' , border: '1.5px solid #C4C4C4', 
          }}
          label="Job Type"
          focused
          onChange={handleJobType}
        >
          <MenuItem style={{ fontSize: '12px' }} value='Full Time'>Full Time</MenuItem>
          <MenuItem style={{ fontSize: '12px' }} value='Part Time'>Part Time</MenuItem>
          <MenuItem style={{ fontSize: '12px' }} value='Online Teaching'>Online Teaching</MenuItem>
        </Select>
      </FormControl>
      <TextField
        name="institutetype"
        label="School / Institute Type"
        type="text"
        style={{marginTop:isXs? '':'20px'}}
        placeholder="Ex. CBSE Board School"
        required
        onChange={(e)=>setInstituteType(e.target.value)}
        inputProps={{
          style: { height: "10px", fontSize: "12px", width: '10rem',  },
        }}
        InputLabelProps={{ sx: { color: "black" } }}
        color="grey"
        focused
      />
    </div>
<TextareaAutosize
  name="jobdescription"
  aria-label="Job Description"
  placeholder="Description"
  required
  focused
  onChange={(e) => setJobDescription(e.target.value)}
  style={{
    height:isXs ? "25px" :'27px',        
    fontSize: "12px",
    width: isXs ? '33rem': '20.5rem',       
    border: '2px solid #C4C4C4', 
    borderRadius: '4px',   
    padding: '8px',         
    outline: 'none',        
  }}
/>

    
    <div style={{ display: 'flex', gap: isXs ? '20px' :'7px' }}>
      <FormControl required sx={{ minWidth: isXs ? "180px" :'100px', maxHeight: 20 }}>
        <InputLabel style={{ fontSize: '12px' }} id="salary-label">Salary</InputLabel>
        <Select
        name="salary"
          labelId="salary-label"
          id="salary-select"
          value={salary}
          style={{ height: '45px', fontSize: '12px', border: '1.5px solid #C4C4C4' }}
          label="Salary"
          focused
          onChange={handlesalary}
        >
          <MenuItem style={{ fontSize: '12px' }} value='Year'>Year</MenuItem>
          <MenuItem style={{ fontSize: '12px' }} value='Month'>Month</MenuItem>
          <MenuItem style={{ fontSize: '12px' }} value='Week'>Week</MenuItem>
          <MenuItem style={{ fontSize: '12px' }} value='Hour'>Hour</MenuItem>
        </Select>
      </FormControl>

      <TextField
      
        name="minsalary"
        label="Min"
        value={min}
        type="number"
        placeholder="₹"
        required
        onChange={(e)=>{      const value = e.target.value;
          if (/^\d{0,11}$/.test(value)) {
           return setMin(value);
          }}}        inputProps={{
          style: { height: "10px", fontSize: "12px", width: '8rem', 
                   appearance: 'none' },
        }}
        InputLabelProps={{ sx: { color: "black" } }}
        color="grey"
        focused
      />

      <TextField
        name="max"
        label="Max"
        value={max}
        type="number"
        placeholder="₹"
        required
        onChange={(e)=>{      const value = e.target.value;
          if (/^\d{0,11}$/.test(value)) {
           return setMax(value);
          }}}
        inputProps={{
          style: { height: "10px", fontSize: "12px", width: '8rem', 
                   appearance: 'none'},
 
        }}
        InputLabelProps={{ sx: { color: "black" } }}
        color="grey"
        focused
      />
    </div>
   

    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <button type="submit" style={{ backgroundColor: '#CB8B65', color: 'white', width: '34rem', height: '2.5rem', border: 'none', borderRadius: '3px' }}>
        Submit
      </button>
    </div>
  </div>
</form>
</div>

          </Dialog>


                <img src={Group35} style={{ position: 'absolute', bottom: '0%', left: '5%', display: isXs ? 'none' : '' }} />
                <img src={Group36} style={{ position: 'absolute', bottom: '0%', right: '5%', display: isXs ? 'none' : '' }} />
                <Modal
                  aria-labelledby="unstyled-modal-title"
                  aria-describedby="unstyled-modal-description"
                  open={open}
                  onClose={handleClose('backdropClick')}
                  slots={{ backdrop: StyledBackdrop }}
                  sx={{ overflow: isXs ? "" : "auto" }}
                >
                  <div className="modal-content" style={{ marginTop: isXs ? '' : '55%', textAlign: isXs ? '' : 'center', width: isXs ? '40%' : '90%' }}>
                    <Button style={{ position: 'absolute', right: '0%', backgroundColor: 'white', borderRadius: '100%', minWidth: '2rem', color: 'black' }} onClick={() => handleClose('close')}>X</Button>
                    <div
                      className="text-center py-2 fw-bold"
                      style={{
                        backgroundColor: '#CB8B65',
                        width: "100%",
                        fontSize: isXs ? "28px" : "22px",
                        marginBottom: "5%",
                        display: 'flex',
                        justifyContent: 'center',
                        gap: '0.5rem',
                        borderTopLeftRadius: '14px',
                        borderTopRightRadius: '14px'
                      }}
                    >
                      <div
                        style={{
                          color: "#FFDB7E",

                        }}
                      >
                        FREE
                      </div>
                      <div
                        style={{
                          color: "white",
                        }}
                      >
                        REGISTRATION
                      </div>

                    </div>
                    {/* <img src={Group92} style={{display: isXs ? "" : ""}}/> */}
                    <form onSubmit={handleSubmit(onSubmitHandler)}>
                      <Box
                        className="d-flex justify-content-center mt-2"
                        component="form"
                        sx={{
                          "& .MuiTextField-root": {
                            m: 1,
                            width: isXs ? "23ch" : "75%",
                          },
                          "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                          {
                            borderWidth: "0.5px",
                          },
                        }}
                        noValidate
                        autoComplete="off"
                      >
                        <Grid container>
                          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                            <div style={{ textAlign: isXs ? 'end' : "center" }}>
                              <TextField
                                {...register("fullName")}
                                name="fullName"
                                id="fullName"
                                label="Full Name"
                                type="text"
                                placeholder="Enter your Full Name"
                                required
                                inputProps={{
                                  style: {
                                    height: "10px",
                                    fontSize: "12px",
                                  },
                                }}
                                InputLabelProps={{
                                  sx: { color: "black" },
                                }}
                                color="grey"
                                focused
                              // onChange={(e) =>
                              //     setFullName(e.target.value)
                              // }
                              />
                              <p style={{ fontSize: "12px", color: "red", marginRight: isXs ? '12%' : '' }}>
                                {errors.fullName?.message}
                              </p>
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                            <div>
                              <TextField
                                {...register("mobileNumber")}
                                name="mobileNumber"
                                id="mobileNumber"
                                label="Mobile Number"
                                type="number"
                                placeholder="Enter your Mobile Number"
                                required
                                inputProps={{
                                  style: {
                                    height: "10px",
                                    fontSize: "12px",
                                  },
                                }}
                                InputLabelProps={{
                                  sx: { color: "black" },
                                }}
                                color="grey"
                                focused
                              // onChange={(e) =>
                              //     setMobileNumber(e.target.value)
                              // }
                              />
                              <p style={{ fontSize: "12px", color: "red", marginLeft: isXs ? '5%' : '' }}>
                                {errors.mobileNumber?.message}
                              </p>
                            </div>
                          </Grid>
                        </Grid>
                      </Box>
                      <Box
                        className="d-flex  mt-1 justify-content-between "
                        component="form"
                        sx={{
                          "& .MuiTextField-root": {
                            m: 1,
                            width: isXs ? "23ch" : "75%",
                          },
                          "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                          {
                            borderWidth: "0.5px",
                          },
                        }}
                        noValidate
                        autoComplete="off"
                      >
                        <Grid container>
                          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                            <div style={{ textAlign: isXs ? 'end' : "center" }}>
                              <TextField
                                {...register("email")}
                                name="email"
                                id="email"
                                label="Email"
                                type='"email'
                                placeholder="Enter your Email"
                                required
                                inputProps={{
                                  style: {
                                    height: "10px",
                                    fontSize: "12px",
                                  },
                                }}
                                InputLabelProps={{
                                  sx: { color: "black" },
                                }}
                                color="grey"
                                focused
                                style={{ marginLeft: "3%" }}
                              // onChange={(e) =>
                              //     setEmail(e.target.value)
                              // }
                              />
                              <p
                                style={{
                                  fontSize: "12px",
                                  color: "red",
                                  marginRight: isXs ? '20%' : ''
                                }}
                              >
                                {errors.email?.message}
                              </p>
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                            <FormControl
                              component="fieldset"
                              style={{ marginLeft: "4%" }}
                            >
                              <FormLabel
                                component="legend"
                                style={{
                                  marginBottom: "0%",
                                  fontSize: isXs ? "0.9vw" : "3vw",
                                  color: "black",
                                  letterSpacing: "0px",
                                }}
                              >
                                Mode of Classes*
                              </FormLabel>
                              <Controller
                                // rules={{ required: true }}
                                control={control}
                                name="classMode"
                                render={({ field }) => (
                                  <RadioGroup
                                    row
                                    aria-label="position"
                                    {...field}
                                    name="position"
                                  >
                                    <FormControlLabel
                                      value="2"
                                      control={<Radio color="primary" />}
                                      label={
                                        <span
                                          style={{
                                            fontSize: isXs ? "1vw" : "3vw",
                                          }}
                                        >
                                          Online
                                        </span>
                                      }
                                    />
                                    <FormControlLabel
                                      value="1"
                                      control={<Radio color="primary" />}
                                      label={
                                        <span
                                          style={{
                                            fontSize: isXs ? "1vw" : "3vw",
                                          }}
                                        >
                                          Offine
                                        </span>
                                      }
                                    />
                                  </RadioGroup>
                                )}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Box>
                      <Box
                        className="d-flex justify-content-center mt-1"
                        component="form"
                        sx={{
                          "& .MuiTextField-root": {
                            m: 1,
                            width: isXs ? "23ch" : "75%",
                          },
                          "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                          {
                            borderWidth: "0.5px",
                          },
                        }}
                        noValidate
                        autoComplete="off"
                      >
                        <Grid container>
                          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                            <div style={{ textAlign: isXs ? 'end' : "center" }}>
                              <TextField
                                name="ccb"
                                id="ccb"
                                label="Course / Class/ Board"
                                type='"text'
                                placeholder="Enter Course You Need"
                                {...register("course")}
                                required
                                inputProps={{
                                  style: {
                                    height: "10px",
                                    fontSize: "12px",
                                  },
                                }}
                                InputLabelProps={{
                                  sx: { color: "black" },
                                }}
                                color="grey"
                                focused
                              // onChange={(e) =>
                              //     setCource(e.target.value)
                              // }
                              />
                              <p style={{ fontSize: "12px", color: "red", marginRight: isXs ? '17%' : '' }}>
                                {errors.course?.message}
                              </p>
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                            <div>
                              <TextField
                                name="subject"
                                {...register("subject")}
                                id="subject"
                                label="Subject"
                                type="text"
                                placeholder="Enter Subject You Need"
                                required
                                inputProps={{
                                  style: {
                                    height: "10px",
                                    fontSize: "12px",
                                  },
                                }}
                                InputLabelProps={{
                                  sx: { color: "black" },
                                }}
                                color="grey"
                                focused
                              // onChange={(e) =>
                              //     setSubject(e.target.value)
                              // }
                              />
                              <p style={{ fontSize: "12px", color: "red", marginLeft: isXs ? '5%' : '' }}>
                                {errors.subject?.message}
                              </p>
                            </div>
                          </Grid>
                        </Grid>
                      </Box>
                      <Box
                        className="d-flex justify-content-center mt-1"
                        component="form"
                        sx={{
                          "& .MuiTextField-root": {
                            m: 1,
                            width: isXs ? "13.8ch" : "75%",
                          },
                          "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                          {
                            borderWidth: isXs ? "2px" : "2px",
                          },
                        }}
                        noValidate
                        autoComplete="off"
                      >
                        <Grid container >
                          <Grid item xs={12} sm={4} md={4} lg={4} xl={4} xxl={4}>
                            <div style={{ textAlign: isXs ? 'end' : "center" }}>
                              <TextField
                                {...register("city")}
                                name="city"
                                id="city"
                                label="City"
                                type='"text'
                                placeholder="Enter City Name"
                                required
                                inputProps={{
                                  style: {
                                    height: "10px",
                                    fontSize: "12px",
                                  },
                                }}
                                InputLabelProps={{
                                  sx: { color: "black" },
                                }}
                                color="grey"
                                focused
                              // onChange={(e) =>
                              //     setCity(e.target.value)
                              // }
                              />
                              <p style={{ fontSize: "12px", color: "red" }}>
                                {errors.city?.message}
                              </p>
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={4} md={4} lg={4} xl={4} xxl={4}>
                            <div style={{ textAlign: 'center' }}>
                              <TextField
                                {...register("area")}
                                name="area"
                                id="area"
                                label="Area"
                                type="text"
                                placeholder="Enter Area Name"
                                required
                                inputProps={{
                                  style: {
                                    height: "10px",
                                    fontSize: "12px",
                                  },
                                }}
                                InputLabelProps={{
                                  sx: { color: "black" },
                                }}
                                color="grey"
                                focused
                              // onChange={(e) =>
                              //     setArea(e.target.value)
                              // }
                              />
                              <p style={{ fontSize: "12px", color: "red" }}>
                                {errors.area?.message}
                              </p>
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={4} md={4} lg={4} xl={4} xxl={4}>
                            <div tyle={{ textAlign: 'start' }}>
                              <TextField
                                {...register("pincode")}
                                name="pincode"
                                id="pincode"
                                label="Pincode"
                                type="text"
                                placeholder="Enter Pincode"
                                required
                                inputProps={{
                                  style: {
                                    height: "10px",
                                    fontSize: "12px",
                                  },
                                }}
                                InputLabelProps={{
                                  sx: { color: "black" },
                                }}
                                color="grey"
                                focused
                              // onChange={(e) =>
                              //     setPincode(e.target.value)
                              // }
                              />
                              <p style={{ fontSize: "12px", color: "red" }}>
                                {errors.pincode?.message}
                              </p>
                            </div>
                          </Grid>
                        </Grid>
                      </Box>
                      <div className="d-flex justify-content-center my-2">
                        {/* <button type="submit" className="btn btn-primary py-2 w-75 fw-bold" style={{ fontSize: '14px', border: '1px solid #CB8B65', backgroundColor: '#FFDB7E', color: '#866D2C', borderRadius: '0', zIndex: 3 }}>Get Free Demo Class</button> */}

                        {loading ? (
                          <Stack
                            sx={{ color: "grey.500" }}
                            spacing={2}
                            direction="row"
                          >
                            <CircularProgress color="success" />
                          </Stack>
                        ) : (
                          <button
                            type="submit"
                            className="btn btn-primary py-2 w-75 fw-bold"
                            style={{
                              fontSize: "14px",
                              border: "1px solid #CB8B65",
                              backgroundColor: "#FFDB7E",
                              color: "#866D2C",
                              borderRadius: "0",
                            }}
                          >
                            Get Free Demo Class
                          </button>
                        )}
                      </div>
                    </form>
                    <div className="d-flex flex-row justify-content-between w-full mb-3">
                      <div
                        className="flex-grow-1 text-center"
                        style={{
                          fontSize: "12px",
                          fontWeight: "900",
                        }}
                      >
                        <small className="block">
                          <span className="">By signing up you agree to our </span>
                          <a href='/terms-conditions' style={{ color: "#0582D2" }}>
                            {" "}
                            Terms and Conditions
                          </a>
                        </small>
                      </div>
                    </div>
                  </div>
                </Modal>
              </div>
            </div>
          </div>
        </Grid>

      </Grid>

      {/* Section3 */}
      <Grid container sx={sxStyle} style={{ marginTop: isXs ? '10%' : '10%' }}>

        <Grid item xs={10} sm={5} md={6} lg={6} xl={6} xxl={6} >
          <div style={{ marginLeft: isXs ? '5%' : '', textAlign: isXs ? '' : 'center' }}>
            <h2 style={{  fontWeight: "bold" }}>
              Experienced Tutors for Personal Tuitions
            </h2>
            <div
              style={{
                color: "#CB8B65",
                marginTop: "3%",
                fontSize: isXs ? "1.5vw" : "4vw",
              }}
            >
              Your Gateway to Personalized Education
            </div>
            <div
              style={{
                marginTop: isXs ? "1%" : "10%",
                fontSize: isXs ? "1.1vw" : "2.5",
                textAlign: "justify",
              }}
            >
              Unlock the full potential of personalized education with our
              experienced tutors, who are readily available to deliver tailored
              learning experiences right at your doorstep or online. At Kanha
              Home Tutions, we understand the importance of matching you with
              the perfect tutor to meet your specific educational needs. Our
              tutors are not only experts in their respective fields but also
              skilled in delivering engaging and effective lessons, whether
              online or in-person. Enjoy the convenience of flexible scheduling
              and affordable rates, ensuring that you can access top-quality
              education from the comfort of your home, no matter where you are
              or what your learning goals might be. Choose Kanha Home Tutions
              for a reliable, personalized learning journey that caters to all
              subjects and preparation for any exam or board.
            </div>
          </div>
        </Grid>

        
        <Grid item xs={10} sm={5} md={6} lg={6} xl={6} xxl={6} style={{ textAlign: 'center' }}>
          <img
            src={Group2111}
            style={{
              width: isXs ? "70%" : "95%",
              marginTop: isXs ? '10%' : '15%'
            }}
          />
        </Grid>

        {/* <Box sx={{position: "relative" , marginTop: "3%" , display: isXs ? "" : "none"}}>
          <img src={cloudshape} alt="" style={{width: isXs ? "100%" : "20rem" , minHeight: isXs ? "0%" : "60rem"}} />
        <div
                style={{
                  color: "#A6653F",
                  fontSize: isXs ? "2.5vw" : '6vw',
                  fontWeight: "bold",
                  textAlign: "center",
                  position: "absolute",
                  top: "6rem",
                  left: "30%",
                  right: "30%"
                }}
              >
                How Can Tutors Benefit?
              </div>

              <Box sx={{position: "absolute" , top: "10rem" , left: "15%" , right: "10%" , display: "flex" , justifyContent: "space-between" , gap: "5rem" , marginTop: "2rem"}}>
                <Box><img src={teacherteaching} alt="" style={{width: isXs ? "18rem" : "15rem"}} /></Box>
                <Box sx={{display: "flex" , flexDirection: "column" , gap: "2rem"}}> 
                  <Typography style={markerStyle} sx={{fontFamily: "jost" , width: "90%" , textAlign: "justify" }}>Kanha Home Tuitions helps tutors quickly access various leads for tuition services and connect with multiple academic institutions and students through a single portal. Be a home tutor, teach at educational institutions, or from your own place, all from one place.</Typography>
                  <Typography sx={{fontFamily: "jost"}}>
                    <ul style={{listStyleType: "circle" , textAlign: "left" , lineHeight: "2rem"}}>
                      <li>Contact parent directly.</li>
                      <li>No commission from your tuition fee.</li>
                      <li> Collect 100% fee from parent directly.</li>
                      <li>Top position in search results</li>
                      <li>Premium phone/what's up/chat support.</li>
                      <li> Risk free money back guarantee.</li>
                      <li>Up to 50% extra contacts free of cost in case you don't get confirmed tution.</li>
                    </ul>
                  </Typography>
                </Box>
              </Box>
        </Box> */}

        


      </Grid>

        {/* <Box sx={{width: isXs ? "" : "100vw" , height: isXs ? "" : "47rem" , backgroundColor: isXs ? "" : "#F6EBDC" , marginTop: isXs ? "" : "2rem" , padding: "1.6rem" , display: isXs ? "none" : "flex" , flexDirection: "column" , gap: "1rem" }}>

                  <Box sx={{ color: "#A6653F", fontSize: "1.4rem" ,  fontWeight: "bold", textAlign: "center", fontFamily: "jost"}}>
                    
                  How Can Tutors Benefit?
                  </Box>

                  <Box sx={{ textAlign: "justify" ,  fontFamily: "jost" , margin: "0" , fontSize: "1rem"}}>
                  Kanha Home Tuitions helps tutors quickly access various leads for tuition services and connect with multiple academic institutions and students through a single portal. Be a home tutor, teach at educational institutions, or from your own place, all from one place.
                  </Box>

                  <Box 
    sx={{
      fontFamily: "jost",
      marginTop: "1.2rem",
      display: 'flex',
      justifyContent: 'center', // Center the content horizontally
      padding: "1rem"
    }}
  >
    <ul 
      style={{ 
        lineHeight: "1.4rem",
        color: "#A6653F",
        fontWeight: "bold",
        padding: 0, // Remove default padding
        margin: 0, // Remove default margin
        // Ensure list items have circle markers
        textAlign: 'left', // Center the text inside list items
        fontSize: "1rem"
      }}
    >
      <li>Contact parent directly.</li> <br />
      <li>No commission from your tuition fee.</li> <br />
      <li>Collect 100% fee from parent directly.</li> <br />
      <li>Top position in search results</li> <br />
      <li>Premium phone/what's up/chat support.</li> <br />
      <li>Risk free money back guarantee.</li> <br />
      <li>Up to 50% extra contacts free of cost in case you don't get confirmed tuition.</li>
    </ul>
  </Box>

          </Box> */}

      {/* Section4 */}


      <div style={{ width: '100%', height: isXs ? '170px':'200px', backgroundColor: '#F6EBDC', display: 'flex', alignItems: 'center',  gap: isXs? "70px":'30px', marginTop:'90px', padding:' 0px 70px', flexDirection: isXs ? '':'column'}}>
        <div style={{ fontSize:isXs? '32px':'20px', textAlign: isXs ? "":'center', fontWeight: '500', lineHeight: isXs ? '40px':'30px' ,marginTop: isXs ?'0px':'20px'}}> Looking Qualified Teachers for Schools & Institutes ? </div>
        
        <button
              className="py-2 px-2"
              type="button"
              style={{
                backgroundColor: "#FFDB7E",
                border: "none",
                color: "#866D2C",
                borderRadius: "5px",
                fontWeight: "bold",
                fontSize: isXs ? "1.5rem" : "1rem",
                width: isXs ? "25%" : "20rem",
                height: isXs ? "4rem" : "3rem",
                boxShadow: "5px  5px 2px rgb(0,0,0, .2)",
              }}
            >
<a style={{textDecoration:'none', color:'#866D2C' }} href="/post-teacher-job">
Post a Teacher Job              
<img
                src={DottedArrow}
                style={{
                  width: "14%",
                  maxWidth: "25px",
                  marginLeft: "5%",
                }}
              />
              </a>
            </button>
        </div>

      <Grid container sx={{ backgroundColor: '#CB8B65', display: 'flex', marginTop: isXs ? '0%' : "0" }}>

        <div className="py-4 d-flex justify-content-center"
          style={{
            color: "white",
            fontWeight: "bold",
            fontSize: "28px",
            width: "100%",
            marginTop: isXs ? '3%' : '12%',
            margin: '0 auto'
          }}
        >
          Why Choose Our Tutors?
        </div>

        <Grid container sx={{ display: 'flex', justifyContent: isXs ? 'space-evenly' : 'center', alignItems: isXs ? '' : 'center', marginTop: isXs ? '1%' : '20%' }}>

          <Grid item xs={10} sm={10} md={3} lg={3} xl={3} xxl={3} sx={{ position: 'relative' }} >
            <div
              className="rounded-5"
              style={{
                backgroundColor: "white",
                padding: "20px",
                position: "relative",
              }}
            >
              <div>
                <img
                  src={Leaderstyle}
                  style={{
                    width: "30%",
                    position: "absolute",
                    top: "-20%",
                  }}
                />
              </div>
              <div className="fw-bolder fs-5 py-2" style={{ marginTop: "15%" }}>
                Proven Expertise:
              </div>
              <div>
                Each tutor is rigorously selected based on their knowledge,
                teaching experience, and ability to adapt to different learning
                styles and needs.
              </div>
              <div style={{ marginBottom: "15%" }}>
                <img
                  src={Pin1}
                  style={{
                    position: "absolute",
                    bottom: "-10%",
                    left: "8%",
                  }}
                />
              </div>
            </div>
            <div className="text-end" style={{ marginTop: "30%" }}>
              <img src={Scale} style={{ display: isXs ? '' : 'none' }} />
            </div>
            <img src={Scale} style={{ position: 'absolute', zIndex: 2, left: '-3rem', bottom: '1rem', width: '30%', display: isXs ? "none" : "" }} />
          </Grid>

          <Grid item xs={10} sm={10} md={3} lg={3} xl={3} xxl={3} sx={{ marginTop: '5%' }}>

            <div
              className="rounded-5"
              style={{
                backgroundColor: "white",
                padding: "20px",
                position: "relative",
              }}
            >
              <div>
                <img
                  src={Pin2}
                  style={{
                    position: "absolute",
                    top: "-12%",
                    left: "8%",
                  }}
                />
              </div>
              <div>
                <img
                  src={Leaderstyle2}
                  style={{
                    width: "30%",
                    position: "absolute",
                    top: "-18%",
                    right: "36%",
                  }}
                />
              </div>
              <div className="fw-bolder fs-5 py-2" style={{ marginTop: "15%" }}>
                Customized Approach:
              </div>
              <div style={{ marginBottom: "15%" }}>
                Our tutors understand that each student is unique. They
                customize their teaching methods to match individual learning
                paces and preferences, ensuring optimal comprehension and
                retention.
              </div>
            </div>
          </Grid>

          <Grid item xs={10} sm={10} md={3} lg={3} xl={3} xxl={3} sx={{ position: 'relative', marginTop: isXs ? '' : '30%', marginBottom: isXs ? '' : '20%' }}>
            <div
              className="rounded-5"
              style={{
                backgroundColor: "white",
                padding: "20px",
                position: "relative",
              }}
            >
              <div>
                <img
                  src={Leaderstyle3}
                  style={{
                    width: "30%",
                    position: "absolute",
                    top: "-20%",
                    right: "8%",
                  }}
                />
              </div>
              <div className="fw-bolder fs-5 py-2" style={{ marginTop: "15%" }}>
                Results-Oriented:
              </div>
              <div>
                Focused on achieving tangible academic improvements, our tutors
                are committed to helping each student reach and surpass their
                academic goals.
              </div>
              <div style={{ marginBottom: "15%" }}>
                <img
                  src={Pin3}
                  style={{
                    position: "absolute",
                    bottom: "-9%",
                    right: "8%",
                  }}
                />
              </div>
            </div>
            <div
              className="col-lg-12 d-flex justify-content-end"
              style={{ backgroundColor: "#CB8B65" }}
            >
              <img
                src={Frame}
                style={{
                  width: "40%",
                  position: "absolute",
                  right: "-25%",
                  marginTop: "30%",
                  display: isXs ? '' : 'none'
                }}
              />
              <img src={Frame} style={{ position: 'absolute', zIndex: 2, right: '-2rem', width: '30%', display: isXs ? "none" : "" }} />
            </div>
          </Grid>

        </Grid>

      </Grid>

      {/* Section5 */}
      <Grid container sx={sxStyle}>

        <div
          className="text-center"
          style={{
            marginTop: isXs ? "5%" : '10%',
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              fontSize: isXs ? "1.6vw" : '4.5vw',
              fontWeight: "bold",
              width: isXs ? "60%" : '70%',
            }}
          >
            How Our Kanha Home Tutions Service Simplifies Your Search for the
            Perfect Tutor
          </div>
          <img
            src={line3}
            style={{
              width: "30%",
              maxWidth: "200px",
              margin: "auto",
              marginTop: isXs ? "1rem" : "2rem",
            }}
          />
        </div>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} sx={sxStyle}>
          <div
            style={{
              marginTop: isXs ? "5%" : "10%",
              backgroundColor: "#F6EBDC",
              width: isXs ? "65%" : '80%',
              height: isXs ? "17.2rem" : '40rem',
              position: "relative",
              borderTopLeftRadius: isXs ? "500px" : '200px',
              borderBottomLeftRadius: isXs ? "500px" : '',
              borderBottomRightRadius: isXs ? "500px" : '',
              borderTopRightRadius: isXs ? "" : '200px',
            }}
          >
            <Grid container >
              <Grid item xs={10} sm={4} md={4} lg={4} xl={4} xxl={4} sx={{ margin: isXs ? '' : '0 auto' }}>
                <img
                  src={Step1}
                  alt="Step1"
                  style={{
                    width: isXs ? "94%" : "100%",
                    backgroundColor: "rgba(255, 255, 255, 0.6)",
                    borderRadius: "50%",
                    marginTop: isXs ? "" : "11%"
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={5} md={8} lg={8} xl={8} xxl={8} style={{ display: "flex", alignItems: "center" }} >
                <div style={{ marginRight: isXs ? '8%' : '0 auto' }}>
                  <div className="d-flex fw-bold" style={{ fontSize: isXs ? "2vw" : '5vw', justifyContent: isXs ? '' : "center" }}>
                    <Grid container justifyContent={isXs ? 'flex-start' : 'center'} sx={{ gap: isXs ? '0.5rem' : '' }}>
                      <Grid item xs={10} sm={2} md={2} lg={2} xl={2} xxl={2}>
                        <div style={{ color: "#CB8B65", textAlign: isXs ? "" : 'center', marginTop: isXs ? '' : '11%' }}>
                          Step 1
                        </div>
                      </Grid>
                      <Grid item xs={10} sm={5} md={5} lg={5} xl={5} xxl={5} sx={{ right: '5%' }}>
                        <div style={{ textAlign: isXs ? "" : 'center' }}>Sign Up Easily</div>
                      </Grid>
                    </Grid>
                  </div>
                  <div style={{ fontSize: isXs ? "1vw" : "4vw", textAlign: isXs ? '' : 'center', width: isXs ? "" : "80%", margin: isXs ? '' : '0 auto', marginTop: isXs ? "2%" : "8%" }}>
                    Start by registering on the Kanha Home Tuitions website or app,
                    or simply contact us directly. This step allows you to clarify
                    your specific tutoring needs and preferences, ensuring we
                    connect you with the ideal candidates.
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} sx={sxStyle} style={{ display: isXs ? '' : 'none' }}>
          <div
            className="d-flex align-items-center py-3"
            style={{
              marginTop: "5%",
              backgroundColor: "#CB8B65",
              width: isXs ? "65%" : '80%',
              height: isXs ? "17.2rem" : '30rem',
              position: "relative",
              borderTopLeftRadius: isXs ? "" : '200px',
              borderBottomLeftRadius: isXs ? "500px" : '',
              borderBottomRightRadius: isXs ? "500px" : '',
              borderTopRightRadius: isXs ? "500px" : '200px'
            }}
          >
            <Grid container sx={{ display: 'flex', justifyContent: isXs ? 'flex-end' : '' }}>
              <Grid item xs={10} sm={7} md={7} lg={7} xl={7} xxl={7} style={{ display: "flex", alignItems: "center" }}>
                <div>
                  <div className="d-flex fw-bold" style={{ fontSize: "2vw", gap: '1rem' }}>
                    <div style={{ color: "#FFDB7E", width:'100px' }}>Step 2</div>
                    <div style={{ color: "white", width: "80%" }}>
                      Post Tutoring Requirements
                    </div>
                  </div>
                  <div
                    style={{
                      fontSize: "1vw",
                      color: "white",
                      width: "90%",
                      marginTop: isXs ? '2%' : ''
                    }}
                  >
                    Create a detailed post outlining your child’s academic needs,
                    preferred schedule, and any special considerations. You can also
                    browse posts from our extensive network of qualified tutors to
                    find the right match.
                  </div>
                </div>
              </Grid>
              <Grid item xs={10} sm={4} md={4} lg={4} xl={4} xxl={4} sx={{ display: 'flex', justifyContent: 'flex-end', }}>
                <img
                  src={Step2}
                  alt="Step2"
                  style={{
                    width: isXs ? "95%" : "120%",
                    backgroundColor: "rgba(255, 255, 255, 0.6)",
                    borderRadius: "50%"
                  }}
                />
              </Grid>
            </Grid>
          </div>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} sx={sxStyle} style={{ display: isXs ? 'none' : '', marginTop: '5%' }}>
          <div
            style={{
              marginTop: "5%",
              backgroundColor: "#CB8B65",
              width: isXs ? "65%" : '80%',
              height: isXs ? "17.2rem" : '40rem',
              position: "relative",
              borderTopLeftRadius: isXs ? "500px" : '200px',
              borderBottomLeftRadius: isXs ? "500px" : '',
              borderBottomRightRadius: isXs ? "500px" : '',
              borderTopRightRadius: isXs ? "" : '200px'
            }}
          >
            <Grid container>
              <Grid item xs={10} sm={5} md={5} lg={5} xl={5} xxl={5} sx={{ margin: isXs ? '' : '0 auto' }}>
                <img
                  src={Step2}
                  alt="Step2"
                  style={{
                    width: isXs ? "93%" : "100%",
                    backgroundColor: "rgba(255, 255, 255, 0.6)",
                    borderRadius: "50%",
                    marginTop: isXs ? "" : "11%"
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={5} md={7} lg={7} xl={7} xxl={7} style={{ display: "flex", alignItems: "center" }}>
                <div style={{ margin: isXs ? '' : '0 auto' }}>
                  <div className="d-flex fw-bold" style={{ fontSize: isXs ? "2vw" : '5vw', justifyContent: isXs ? '' : "center" }}>
                    <Grid container justifyContent={isXs ? 'flex-start' : 'center'} >
                      <Grid item xs={10} sm={2.5} md={2.5} lg={2.5} xl={2.5} xxl={2.5}>
                        <div style={{  color: "#FFDB7E", marginRight: "0.5rem", textAlign: isXs ? "" : 'center', marginTop: isXs ? '' : '11%'  }}>
                          Step 2
                        </div>
                      </Grid>
                      <Grid item xs={10} sm={5} md={5} lg={5} xl={5} xxl={5} sx={{ right: '5%' }}>
                        <div style={{ textAlign: isXs ? "" : 'center', color: 'white' }}>Post Tutoring Requirements</div>
                      </Grid>
                    </Grid>
                  </div>
                  <div style={{ fontSize: isXs ? "1vw" : "4vw", color: 'white', textAlign: isXs ? '' : 'center', width: isXs ? "" : "80%", margin: isXs ? '' : '0 auto', marginTop: isXs ? "" : "8%" }}>
                    Create a detailed post outlining your child’s academic needs,
                    preferred schedule, and any special considerations. You can also
                    browse posts from our extensive network of qualified tutors to
                    find the right match.
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} sx={sxStyle} style={{ marginTop: '5%' }}>
          <div
            style={{
              marginTop: "0.2%",
              backgroundColor: "#F6EBDC",
              width: isXs ? "65%" : '80%',
              height: isXs ? "17.2rem" : '42rem',
              position: "relative",
              borderTopLeftRadius: isXs ? "500px" : '200px',
              borderBottomLeftRadius: isXs ? "500px" : '',
              borderBottomRightRadius: isXs ? "500px" : '',
              borderTopRightRadius: isXs ? "" : '200px'
            }}
          >
            <Grid container>
              <Grid item xs={10} sm={4} md={4} lg={4} xl={4} xxl={4} sx={{ margin: isXs ? '' : '0 auto' }}>
                <img
                  src={Step3}
                  alt="Step3"
                  style={{
                    width: isXs ? "94%" : "100%",
                    backgroundColor: "rgba(255, 255, 255, 0.6)",
                    borderRadius: "50%",
                    marginTop: isXs ? "" : "11%"
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={5} md={8} lg={8} xl={8} xxl={8} style={{ display: "flex", alignItems: "center" }}>
                <div style={{ margin: isXs ? '' : '0 auto' }}>
                  <div className="d-flex fw-bold" style={{ fontSize: isXs ? "2vw" : '5vw', justifyContent: isXs ? '' : "center" }}>
                    <Grid container justifyContent={isXs ? 'flex-start' : 'center'} sx={{ gap: isXs ? '0.5rem' : '' }}>
                      <Grid item xs={10} sm={2} md={2} lg={2} xl={2} xxl={2}>
                        <div style={{ color: "#CB8B65", textAlign: isXs ? "" : 'center', marginTop: isXs ? '' : '11%' }}>
                          Step 3
                        </div>
                      </Grid>
                      <Grid item xs={10} sm={8} md={8} lg={8} xl={8} xxl={8} sx={{ right: '5%' }}>
                        <div style={{ textAlign: isXs ? "" : 'center' }}>Choose the Ideal Tutor</div>
                      </Grid>
                    </Grid>
                  </div>
                  <div style={{ fontSize: isXs ? "1vw" : "4vw", textAlign: isXs ? '' : 'center', width: isXs ? "95%" : "80%", margin: isXs ? '' : '0 auto', marginTop: isXs ? "2%" : "8%" }}>
                    After posting your requirements, you'll receive responses from
                    our vetted tutors. Arrange a demo class to assess the tutor’s
                    compatibility with your child’s learning style and needs. Once
                    satisfied, you can hire the tutor and discuss any further
                    details directly.
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>

      </Grid>

      {/* Section6 */}
      <div style={{marginTop: '8rem'}}>
        <img src={Frame24} alt="Frame24"
          style={{
            position: "absolute",
            width: "100%",
            display:isXs ? '':'none',
            height: "auto",
          }}
        />
        <img src={Group221} alt="Group221"
          style={{
            position: "absolute",
           paddingTop: '4.2rem',
            left: '3.3rem',
            width: isXs ? "96%" : '0%',
            
          }}
        />
       
   </div>

      <div
            className="container-fluid"
            style={{
              position: "relative",
              overflow: "hidden",
            zIndex: 3,
            backgroundColor: isXs ? '':"#CB8B65",
            padding: isXs ? '' : '3rem 0rem',
            height:isXs? "auto":"570px" ,


            }}
          >
            <div
              className="text-center"
              style={{
                marginTop: isXs ? "6%" : '1%',
                height: isXs ? '20rem' : '',
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  fontSize: isXs ? "2.2vw" : '6.6vw',
                  fontWeight: "bold",
                  width: isXs ? "55%" : "90%",
                  color: "white",
                }}
              >
                Kickstart Your Child’s Academic Success Today!
              </div>
              <div
                style={{
                  fontSize: isXs ? "1.2vw" : "4vw",
                  width: isXs ? "70%" : "90%",
                  color: "white",
                  marginTop: isXs ? "3%" : "8%",
                  textAlign: isXs ? '' : 'center'
                }}
              >
                Forget the hassle of endless searching for 'tuition near me'. With
                Kanha Home Tutions, discover a personalized, efficient, and reliable
                approach to finding the perfect tutor for your child. Join our
                community of satisfied parents and witness your child's educational
                transformation.
              </div>
              <div
                className="d-flex justify-content-evenly"
                style={{
                  width: isXs ? "60%" : "85%",

                  marginTop: isXs ? "4%" : "10%",
                  fontSize: "1.4vw",
                }}
              >
                <button className="py-2"
                  type="button"
                  onClick={handleOpen}
                  style={{
                    backgroundColor: "#FFDB7E",
                    color: "#866D2C",
                    border: "none",
                    borderRadius: "3px",
                    width: isXs ? '70%' : '20rem',
                  height: isXs ? '4rem' : '5rem',
                    fontWeight: "bold",
                    fontSize: isXs ? '1.5rem' : '1.6rem',
                    boxShadow: '5px  5px 2px rgb(0,0,0, .2)'
                  }}
                >
                  Post Your Requirments
                </button>
                {/* <div style={{ color: 'white' }}> | </div>
                        <button style={{ backgroundColor: '#FFDB7E', color: '#866D2C', border: 'none', borderRadius: '3px', width: '40%', fontWeight: 'bold' }}>Contact Us</button> */}
              </div>
              <img src={Globe} style={{ position: 'absolute', zIndex: 2, right: '-1rem', top: '1rem', width: '12%', display: isXs ? "none" : "" }} />
            </div>
          
          <img src={NoteBook} style={{ position: 'absolute', zIndex: 2, left: '3rem', bottom: '-1rem', width: '15%', display: isXs ? "none" : "" }} />
          </div>

{/* 1 To 12 Classes Section*/}
          <div style={{ height: isXs ? '800px' : '1150px', backgroundColor: '#F6EBDC', width: '100%', marginTop: isXs ? '2rem':'0rem' ,marginBottom: isXs ? '0rem':'7rem' }}>
      <div style={{ textAlign: "center" }}>
        <div style={{ fontSize: "28px", paddingTop:isXs? '120px': '50px', fontWeight: 'bold' }}>Explore Categories</div>
      </div>
      <div style={{ fontSize: '1.2rem', fontWeight: 'bolder', marginTop:isXs ?'10px':'20px', textAlign:isXs? 'center':'center' }}>
        Tutors for home and online Tution
      </div>
      <div style={{ 
          display: 'grid', 
          gridTemplateColumns: isXs ? 'repeat(5, 250px)' : 'repeat(2, 1fr)', 
          justifyItems: 'center', 
          alignItems: 'center', 
          justifyContent:'center',
          padding: isXs ? '2rem 1rem': '2rem 1rem',
          gap:isXs? '':'0.7rem',
          rowGap:isXs? '50px':'2rem',
        }}>
        
        {[ 
          { img: class1_6, label: "Class 1-6 Tution" },
          { img: class7, label: "Class 7 Tution" },
          { img: class8, label: "Class 8 Tution" },
          { img: class9, label: "Class 9 Tution" },
          { img: class10, label: "Class 10 Tution" },
          { img: class11, label: "Class 11 Tution" },
          { img: class12, label: "Class 12 Tution" },
          { img: IIT, label: "IIT-JEE Tution" },
          { img: Neet, label: "NEET Tution" },
          { img: NDA, label: "NDA Tution" },
        ].map((item, index) => (
          <Link style={{textDecoration:'none', color:'black'}} to="/student-signin" key={index}>
          <div key={index} style={{ gap: '10px', display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
            <div>
              <img 
                src={item.img} 
                alt="" 
                style={{ 
                  objectFit: 'cover', 
                  borderRadius: '7px', 
                  height:isXs? '150px':'120px', 
                  width: '100%', 
                  maxWidth: '220px' 
                }} 
              />
            </div>
            <div>{item.label}</div>
          </div>
          </Link>
        ))}
      </div>
    </div>


    <div style={{ marginTop: '80px ', marginBottom:isXs? '':'100px' }}>
    <div style={{ position: 'relative' }}>
  <div style={{ textAlign: 'center', backgroundColor: isXs ? '' : '#C9875F', height: isXs ? '' : '730px', borderBottomLeftRadius: '50px', borderTopRightRadius: '50px', display: 'flex', justifyContent: 'center', margin: isXs ? "" : '0px 5%' }}>
    <img style={{ width: isXs ? '80%' : '0%' }} src={QuicklinkBg} alt="QuicklinkBg" />
    <div style={{ position: 'absolute', top: '32px', fontSize: '28px', fontWeight: 'bold', left: isXs ? '44.5%' : '', color: 'white', display: 'flex', flexDirection: 'column', textAlign: isXs ? '' : 'center' }}>
      Quick Links <img style={{ width: '70px' }} src={Linenew} alt="LineNEW" />
    </div>
    <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
  <div style={styles.grid}>
    {quickLinks.slice(0, 6).map((boxText , index) => (
      <div onClick={() => toggleSidebar(boxText.quick_link_name, boxText.seo_pages)} key={index}  style={styles.box}>
        {boxText.quick_link_name}
        <img src={ArrowNew} style={{ height: '30px', marginLeft: '10px' }} alt="" /> 
      </div>
    ))} 
  </div>
</div>
  </div>
</div>

      {/* Sidebar */}
      <div style={{ position: 'absolute', zIndex: '1000' }}>
        <div ref={sidebarRef} style={{ position: 'fixed', top: 40, right: 0, height: isXs ? '530px':'750px' , width: isXs ? '390px':'340px', backgroundColor: '#efe8e8', color: 'black', transform: isOpen ? 'translateX(00)' : 'translateX(100%)', transition: 'transform 0.3s', boxShadow: '1px 2px 2px rgb(10, 10, 10 ,0.2)', alignItems:'center',  }}>
          <div style={{ display: 'flex', gap: "0px", backgroundColor: '#A6653F', color: 'white', padding: '10px 10px' }}>
          <div style={{ fontWeight: '500', fontSize: '18px', paddingRight: '16px', top: '0', position: 'sticky', zIndex:'1000' }}>

{/* Add this button text */}
{selectedText} 

            </div>

            {isOpen && (  
    <div onClick={() => toggleSidebar()} style={{ cursor: 'pointer', color: 'white' }}>
      {/* <CloseIcon style={{ backgroundColor: '#f7dc99', color: 'black', borderRadius: '50%', position: 'absolute', right: '-2', top: '0' }} /> */}
    </div>
  )}          </div>
         <div style={{  overflowY: 'auto', maxHeight: isXs? '480px':'700px', paddingBottom: '10px', paddingTop:'10px' }}>
  <div style={{ paddingLeft: '10px' }}>


    {seoPages.map((seoPage, index) => (
      <Link to={`/${seoPage.txt_slug.toLowerCase().replaceAll(
        " ",
        "-"
      )}`} key={index} style={{ color: 'black', textDecoration: 'none', fontSize: '16px' }}>
        <li style={{textIndent:'-1.5rem', paddingLeft:'2rem ',marginBottom:'15px' }} className="listiem">{seoPage.txt_meta_title}</li> 
      </Link>

    ))}
  </div>
</div>
        </div>
      </div>
   
    </div>


      {/* Section7 Web */}
      <Grid container sx={{ display: isXs ? 'flex' : 'none', marginTop: '5%', display: isXs ? "" : "none"  }}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
          <div
            className="d-flex justify-content-center"
            style={{
              position: "relative",
              marginTop: "10%",
              marginBottom: "10%",
            }}
          >
            <img
              src={Frame25}
              alt="Frame24"
              style={{
                position: "absolute",
                width: "78%",
                height: "auto",
                zIndex: 2,
                left: "8.5rem",
                top: "-3rem",
              }}
            />
            <div
              className="d-flex align-items-center"
              style={{
                borderBottomRightRadius: "10px",
                height: "20rem",
                width: "80%",
                backgroundColor: "#F6EBDC",
                borderTopRightRadius: "150px",
                borderBottomLeftRadius: "150px",
              }}
            >
              <div
                style={{ 
                  maxWidth: "40%",
                  marginLeft: "6vw",
                  marginBottom: "5%",
                }}
              >
               
                <div style={{ fontSize: "1.5vw", width: "90%" }}>
                Download the Kanha Home Tuitions Student and Tutor apps now on Google Play Store!
                </div>
             
              </div>
            </div>
          </div>
        </Grid>
      </Grid>

      {/* Section7 Mobile */}
      <Grid container sx={{ display: isXs ? 'none' : '', marginTop: '5%', display: isXs ? "none" : "" }} >
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} sx={{ display: 'flex', justifyContent: 'center', position: 'relative' }}>
          <img
            src={GroupPhone}
            alt="GroupPhone"
            style={{
              position: "absolute",
              width: "50%",
              height: "auto",
              zIndex: 1
            }}
          />
          <img src={PhoneDoodlePlus} style={{ position: 'absolute', zIndex: 2, left: '3rem', top: '7rem' }} />
          <img src={PhoneDoodleMultiply} style={{ position: 'absolute', zIndex: 2, right: '3rem', top: '8rem' }} />

        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
          <div
            className="d-flex justify-content-center"
            style={{
              position: "relative",
              marginTop: "20%",
              marginBottom: "10%",
            }}>
            <div
              className="d-flex align-items-center"
              style={{
                borderBottomRightRadius: "10px",
                height: "25rem",
                width: "90%",
                
                backgroundColor: "#F6EBDC",
                borderTopRightRadius: "50px",
                borderBottomLeftRadius: "50px",
                display: 'flex', justifyContent: 'center'
              }}
            >
              <div
                style={{
                  minWidth: '100%',
                  marginTop: '45%',
                  marginBottom: "5%",
                }}
              >
                
                <div style={{ fontSize: "4.2vw", width: "80%", margin: '0 auto', marginTop: '2%', textAlign: 'center', marginBottom: '15%' }}>
                Download the Kanha Home Tuitions Student and Tutor apps now on Google Play Store!
                </div>
                <Grid item xs={10} sm={3} md={3} lg={3} xl={3} xxl={3}  container spacing={3} sx={{ margin: '0 auto' }}>
                  {/* <div style={{ marginTop: isXs ? "10%" : "18%", minWidth: '100%', textAlign: 'center' }}>
                    <a href="">
                      <img src={GooglePlay} alt="GooglePlay" width="70%" />
                    </a>
                    <a href="">
                      <img
                        src={AppStore}
                        alt="AppStore"
                        width="70%"
                        style={{ marginTop: "10%" }}
                      />
                    </a>
                  </div> */}
                </Grid>
                <img src={PhoneDoodleBook} style={{ position: 'absolute', zIndex: 2, left: '4rem', bottom: '2rem' }} />
                <img src={PhoneDoodleDivide} style={{ position: 'absolute', zIndex: 2, right: '3rem', bottom: '1rem' }} />
              </div>
            </div>
          </div>
        </Grid>
      </Grid>

      <Box >
          <Button onClick={() => {navigate('/accountstudent')}} sx={{color:'white'}}>abc</Button>
          <Button onClick={() => {navigate('/accountteacher')}} sx={{color:'white'}}>xyz</Button>
      </Box>


      <Footer />
      

      
    </>
    
  );
};


const Backdrop = React.forwardRef((props, ref) => {
  const { open, className, ...other } = props;
  return (
    <div
      className={clsx({ "base-Backdrop-open": open }, className)}
      ref={ref}
      {...other}
    />
  );
});

Backdrop.propTypes = {
  className: PropTypes.string.isRequired,
  open: PropTypes.bool,
};

const Modal = styled(BaseModal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal-content {
    background-color: white; 
    border-radius: 14px; 
  }
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;


export default Landing;


